import { useState, useEffect, useMemo } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";
import SelectBox from "../../components/items/SelectBox";

import Table from "../../components/rows/Table";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

export default function LeaseDetailAdditionalAdd({ table }) {
  const text = lang.zh;

  let navigate = useNavigate();

  const { token } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    console.log(data);
    const response = await CallApi(token, "POST", table, data);
    console.log(response);
    if (response.success && response.success > 0) {
      // redirect(`/`);
      // return navigate("/accounting/");
      // return navigate(`/${table}`);
      navigate("../");
    }
  };

  return (
    <>
      <h2>增加附加物品</h2>
      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section>
          <Row>
            <Col className="col-12 mb-2">
              <InputBox
                id="name"
                label="項目名稱"
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox id="description" label="說明" type="text" />
            </Col>

            <Col className="col-12 mb-2">
              <Button className="col-12 btn btn-primary p-2" text="新增" />
            </Col>
          </Row>
        </Section>
      </Form>
    </>
  );
}
