import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
    fontFamily: "Noto Sans TC",
  },
  section: {
    marginBottom: 10,
  },
  sectionWithBorder: {
    marginBottom: 10,
    padding: 1,
    border: "1px solid black",
  },
  p2section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center",
  },
  text: {
    fontSize: 12,
  },

  rowtext: {
    fontSize: 12,
    marginBottom: 5,
  },

  // row: {
  //   flexGrow: 1,
  //   flexDirection: "row",
  //   fontSize: 7,
  // },
  // col: {
  //   flexGrow: 1,
  //   flexDirection: "column",
  // },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  colLeft: {
    width: "25%", // 左侧列宽度
    paddingRight: 10,
  },
  colRight: {
    width: "75%", // 右侧列宽度
  },

  p2row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  p2colLeft: {
    width: "5%", // 左侧列宽度
    // paddingRight: 5,
  },
  p2colRight: {
    width: "95%", // 右侧列宽度
  },
  p2rowtext: {
    fontSize: 8,
    marginBottom: 5,
    maxWidth: "100%",
  },

  signatureSection: {
    marginBottom: 10,
    // paddingTop: 40,
  },
  signatureRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 70,
  },
  signatureCol: {
    width: "40%",
    borderTop: "1px solid black",
    // textAlign: "center",
    paddingTop: 5,
  },
  spacer: {
    marginVertical: 5,
  },

  boldText: {
    // fontFamily: "Noto Sans TC Bold",
    fontWeight: 'bold',
  },
  italicText: {
    fontStyle: 'italic',
  },

  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    // width: '14.28%',
    width: '12.5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 9,
    textAlign: 'center',
  },
});

export default styles;