import { useState, useEffect, useMemo } from "react";

import { BasicPage } from "../../components/base/BasicPage";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { increment, decrement, increase } from "../../redux/actions";

import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import Separator from "../../components/rows/Separator";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

export default function Dashboard() {
  const { token } = useAuth();
  const { t, i18n } = useTranslation();

  const [product, setSalling] = useState([]);
  const [client, setClient] = useState([]);

  const tableSalling = "sales-product-report";
  const tableClient = "client";
  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", tableSalling);
      const response2 = await CallApi(token, "GET", tableClient);
      if (response.success && response.success > 0)
        if (response.success && response.success > 0)
          setSalling(response.tabledata);
      if (response2.success && response2.success > 0)
        setClient(response2.tabledata);
    };
    getData();
  }, [tableSalling, tableClient]);

  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  console.log(product);
  console.log(client);
  return (
    <>
      <h2 className="title">主頁概覽</h2>
      <div>統計圖表，本月事件及簡報</div>
      <Row>
        <Col className="col-6">
          <Row>
            <Col className="m-1 me-3 p-3 block">
              <h2>XXXX Mop</h2>
              <p>本月銷售金額</p>
              <p>bar chart</p>
              <p>比過去12個月平均多 XXX%</p>
            </Col>
            <Col className="m-1 ms-3 p-3 block">
              <h2>2</h2>
              <p>本月銷售單數</p>
              <p>bar chart</p>
              <p>比過去12個月平均多 XXX%</p>
            </Col>
          </Row>
          <Row>
            <Col className="m-1 p-3 block">
              <h2>本月最熱賣貨品</h2>
              <Separator />
              <table className="table">
                <thead>
                  <tr>
                    <th>貨品ID</th>
                    <th>貨品名稱</th>
                    <th>數量</th>
                    <th>金額(MOP)</th>
                  </tr>
                </thead>
                <tbody>
                  {product.map((rows, index) => (
                    <tr key={index}>
                      <td>{rows.PId}</td>
                      <td>{rows.ProductName}</td>
                      <td>{rows.TotalQuantity}</td>

                      <td>{rows.TotalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
        <Col className="col-6">
          <Row>
            <Col className="m-1 p-3 block">
              <h2>本月最大客戶</h2>
              <Separator />
              <table className="table">
                <thead>
                  <tr>
                    <th>客戶編號</th>
                    <th>名稱</th>
                    <th>金額(MOP)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>100</td>
                    <td>XXXX</td>
                    <td>1000</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <div>{t("hello")}</div> */}
      {/* <div>
        Counter from Store is : {counter}
        <button onClick={() => dispatch(increment())}>增加</button>
        <button onClick={() => dispatch(decrement())}>減少</button>
      </div> */}
    </>
  );
}
