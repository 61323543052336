import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";

// Base Pages
import { LoginPage } from "../pages/base/Login";

// Authenticated Pages
import { HomePage } from "../pages/Home";
import PageMain from "../pages/PageMain";
import PageMainWithReactTable from "../pages/PageMainWithReactTable";

import Dashboard from "../pages/admin/Dashboard";
import { ProfilePage } from "../pages/admin/Profile";
import ProfilePage2 from "../pages/admin/Profile2";
import { SettingsPage } from "../pages/admin/Settings";

import StaffLayout from "../components/pageLayout/StaffLayout";
import { StaffPage } from "../pages/admin/staff/Staff";
import StaffAdd from "../pages/admin/staff/StaffAdd";

import {
  SalesSummary,
  LeaseSummary,
  LeasingSummary,
  StaffSummary,
} from "../utilities/ReactTableHead";

import ShopOrderUI from "../pages/sales/ShopOrderUI";
import SalesLayout from "../components/pageLayout/SalesLayout";

import SalesDetail from "../pages/sales/SalesDetail";
import PDFSheet from "../pages/sales/PDFSheet";

import LeaseLayout from "../components/pageLayout/LeaseLayout";
import LeaseAdd from "../pages/lease/LeaseAdd";
import LeaseDetail from "../pages/lease/LeaseDetail";
import LeaseRenewal from "../pages/lease/LeaseRenewal";
import LeaseItemAdd from "../pages/lease/LeaseItemAdd";
import LeasePDFSheet from "../pages/lease/PDFSheet";
import LeasePDF from "../pages/lease/LeasePDF";
import LeaseAdditionalAdd from "../pages/lease/LeaseAdditionalAdd";
import LeaseDetailAdditionalAdd from "../pages/lease/LeaseDetailAdditionalAdd";
import LeasePayment from "../pages/lease/LeasePayment";

import ClientLayout from "../components/pageLayout/ClientLayout";
import ClientAdd from "../pages/client/ClientAdd";

import InventoryLayout from "../components/pageLayout/InventoryLayout";
import InventoryAdd from "../pages/inventory/InventoryAdd";

import ProductLayout from "../components/pageLayout/ProductLayout";
import ProductAdd from "../pages/product/ProductAdd";
import ProductType from "../pages/product/ProductType";

import SupplierLayout from "../components/pageLayout/SupplierLayout";
import SupplierAdd from "../pages/supplier/SupplierAdd";

import ManufacturerLayout from "../components/pageLayout/ManufacturerLayout";
import ManufacturerAdd from "../pages/manufacturer/ManufacturerAdd";

import AccountingLayout from "../components/pageLayout/AccountingLayout";
import Accounting, { Tabletext as atext } from "../pages/accounting/Accounting";
import AccountingAdd from "../pages/accounting/AccountingAdd";
import AccountingChart, {
  action as chartAction,
  // loader as chartLoader,
} from "../pages/accounting/AccountingChart";

// import { SideBars_1 } from "../pages/SideBars_1";
// import { SideBars_2 } from "../pages/SideBars_2";

// import { ProtectedLayout } from "../components/base/_ProtectedLayout";
import { MainLayout } from "../components/base/MainLayout";

import { AuthLayout } from "../components/base/AuthLayout";

import Page404 from "../pages/Page404";

// ideally this would be an API call to server to get logged in token data

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const token = window.localStorage.getItem("token"); //localStorage要設計時間令它過期或消失
      resolve(token);
    }, 3000)
  );

// for error
// const getUserData = () =>
//   new Promise((resolve, reject) =>
//     setTimeout(() => {
//       reject("Error");
//     }, 3000)
//   );

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    loader: () => defer({ userPromise: getUserData() }),
    errorElement: <Page404 />,

    children: [
      { path: "/", element: <LoginPage /> },
      {
        path: "/main",
        element: <MainLayout />,
        children: [
          { path: "dashboard", element: <Dashboard /> },
          { path: "profile", element: <ProfilePage /> },
          { path: "profile2", element: <ProfilePage2 /> },
          // { path: "home", element: <HomePage /> },
          { path: "settings", element: <SettingsPage /> },

          // { path: "sidebars_1", element: <SideBars_1 /> },
          // { path: "sidebars_2", element: <SideBars_2 /> },

          // { path: "supplier", element: <SupplierLayout /> },

          // sallingUI
          { path: "salling", element: <ShopOrderUI table="salling" /> },

          // Lease
          {
            path: "lease",
            element: <LeaseLayout />,
            children: [
              {
                index: true,
                // element: <PageMain title="租賃" table="lease" />,
                element: (
                  <PageMainWithReactTable
                    title="租賃"
                    table="lease"
                    col={LeaseSummary()}
                  />
                ),
              },
              { path: "add", element: <LeaseAdd table="lease" /> },
              {
                path: "renewal/:itemId",
                element: <LeaseRenewal table="lease" />,
              },
              {
                path: "detailadditional/:itemId",
                element: (
                  <LeaseDetailAdditionalAdd table="lease-detail-additional" />
                ),
              },
              {
                path: "rentpayment/:itemId",
                element: <LeasePayment table="lease-payment" />,
              },

              {
                path: "detail/:itemId",
                element: <LeaseDetail />,
                children: [
                  {
                    path: "agreement",
                    element: <LeasePDFSheet title="Lease agreement" />,
                  },
                  {
                    path: "leasePDF",
                    element: <LeasePDF title="Invoice" />,
                  },
                  {
                    path: "receipt",
                    element: <LeasePDFSheet title="Receipt" />,
                  },
                ],
              },
              {
                path: "item",
                element: <PageMain title="租賃" table="lease-item" />,
              },
              {
                path: "itemadd",
                element: <LeaseItemAdd table="lease-item" />,
              },
              {
                path: "additional",
                element: <PageMain title="租賃" table="lease-additional" />,
              },
              {
                path: "additionaladd",
                element: <LeaseAdditionalAdd table="lease-additional" />,
              },
            ],
          },

          // sales
          {
            path: "sales",
            element: <SalesLayout />,

            children: [
              {
                index: true,
                // element: <PageMain title="銷售" table="salling" />,
                element: (
                  <PageMainWithReactTable
                    title="銷售"
                    table="salling"
                    col={SalesSummary()}
                  />
                ),
              },
              {
                path: "detail/:orderId",
                element: <SalesDetail />,
                children: [
                  {
                    path: "quotation",
                    element: <PDFSheet title="Quotation" />,
                  },
                  { path: "invoice", element: <PDFSheet title="Invoice" /> },
                  { path: "receipt", element: <PDFSheet title="Receipt" /> },
                ],
              },
            ],
          },

          // client
          {
            path: "client",
            element: <ClientLayout />,
            children: [
              {
                index: true,
                element: <PageMain title="客人" table="client" />,
              },
              { path: "add", element: <ClientAdd table="client" /> },
            ],
          },

          // inventory
          {
            path: "inventory",
            element: <InventoryLayout />,
            children: [
              {
                index: true,
                element: <PageMain title="倉存" table="inventory" />,
              },
              { path: "add", element: <InventoryAdd table="inventory" /> },
            ],
          },

          // product
          {
            path: "product",
            element: <ProductLayout />,
            children: [
              {
                index: true,
                element: <PageMain title="產品" table="product" />,
              },
              { path: "add", element: <ProductAdd table="product" /> },
              { path: "type", element: <ProductType table="product-type" /> },
            ],
          },

          // supplier
          {
            path: "supplier",
            element: <SupplierLayout />,
            children: [
              {
                index: true,
                element: <PageMain title="供應商" table="supplier" />,
              },
              { path: "add", element: <SupplierAdd table="supplier" /> },
            ],
          },

          // manufacturer
          {
            path: "manufacturer",
            element: <ManufacturerLayout />,
            children: [
              {
                index: true,
                element: <PageMain title="製造商" table="manufacturer" />,
              },
              {
                path: "add",
                element: <ManufacturerAdd table="manufacturer" />,
              },
            ],
          },

          // accounting
          {
            path: "accounting",
            element: <AccountingLayout />,
            children: [
              {
                index: true,
                element: (
                  <PageMain title="會計" table="accounting" tablehead={atext} />
                ),
              },
              { path: "add", element: <AccountingAdd table="accounting" /> },
              {
                path: "chart",
                element: <AccountingChart table="accounting-chart" />,
              },
            ],
          },

          // admin setting
          // { path: "staff", element: <StaffPage /> },
          {
            path: "staff",
            element: <StaffLayout />,
            children: [
              {
                index: true,
                element: (
                  <PageMainWithReactTable
                    title="員工"
                    table="staff"
                    col={StaffSummary()}
                  />
                  // 變成格格 按入上方是資料 下方是登入紀錄
                ),
              },
              { path: "add", element: <StaffAdd table="staff" /> },
              {
                path: "chart",
                element: <AccountingChart table="staff-chart" />,
              },
            ],
          },

          // Error
          { path: "*", element: <Page404 /> },
        ],
      },
    ],
  },
  // { path: "sidebars_1", element: <SideBars_1 /> },
  // { path: "sidebars_2", element: <SideBars_2 /> },
]);
