import { useOutlet } from "react-router-dom";
import Section from "../rows/Section";
import { SubBar } from "../base/SubBar";

import {
  GetAppBarSection,
  GetAppBarSectionUrlsByID,
} from "../../utilities/Util";

export default function InventoryLayout() {
  const appBarSection = "store_collapse";
  const appBarLabel = "inventory";
  const appBarPath = GetAppBarSectionUrlsByID(appBarSection, appBarLabel);

  const outlet = useOutlet();

  return (
    <>
      <h1>Inventory</h1>
      <Section>
        <SubBar
          pages={[
            { label: "總結", path: appBarPath },
            // { label: "列表", path: "/supplier/list" },
            { label: "新增", path: `${appBarPath}/add` },
          ]}
        />
      </Section>
      <Section>{outlet}</Section>
    </>
  );
}
