import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    row: {
        marginTop: 100,
        flexGrow: 1,
        flexDirection: 'row',
    },
    leftcol: {
        width: '50%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    rightcol: {
        width: '50%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    col: {
        // flexGrow: 1,
        flexDirection: 'column',
        borderTop: 1,
    },
});

const FooterRowQnI = () => {
    return (
        <View style={styles.row}>
            <View style={styles.leftcol}>
                <Text>Approved By:</Text>
                <Text>MEDICAL BROTHERS LTD</Text>
            </View>
            <View style={styles.rightcol}>
                <Text></Text>
                <Text style={{ borderTop: 1}}>Authorized Signature & Compay chop</Text>
            </View>
        </View>
    )
}
export default FooterRowQnI