import { BasicPage } from "../../components/base/BasicPage";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { increment, decrement, increase } from "../../redux/actions";

export const ProfilePage = () => {
  const { t, i18n } = useTranslation();

  const counter = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  return (
    <>
      <div>hihi</div>
      <div>{t("hello")}</div>
      <div>
        Counter from Store is : {counter}
        <button onClick={() => dispatch(increment())}>增加</button>
        <button onClick={() => dispatch(decrement())}>減少</button>
      </div>
    </>
  );
};
