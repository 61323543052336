import React from "react";
import { useOutletContext } from "react-router-dom";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";

import styles from "../../components/pdf_lease/styles";

import CompanyMarkLogo from "../../components/pdf_lease/pdfbase/CompanyMarkLogo";
import FooterQnITerms from "../../components/pdf_lease/pdfbase/FooterQnITerms";
import FooterReceiptTerms from "../../components/pdf_lease/pdfbase/FooterReceiptTerms";
import FooterRowR from "../../components/pdf_lease/pdfbase/FooterRowR";
import FooterRowQnI from "../../components/pdf_lease/pdfbase/FooterRowQnI";
import HeaderRow from "../../components/pdf_lease/pdfbase/HeaderRow";

// // 导入支持中文的字体
// import font from "/fonts/NotoSansTC-Regular.ttf"; // 请替换为实际的中文字体路径

// // 注册字体
// Font.register({
//   family: "ChineseFont",
//   src: font,
// });

// 注册字体;
Font.register(
  {
    family: "Noto Sans TC",
    src: "/fonts/NotoSansTC-Regular.otf",
  },
  {
    family: "Noto Sans TC Bold",
    src: "/fonts/NotoSansTC-Bold.otf",
  }
);

// Font.register({
//   family: "Noto_Sans",
//   // src: `/pdfbase/Noto_Sans_TC/NotoSansTC-Regular.otf`,
//   src: `/media/Noto_Sans_TC/NotoSansTC-Regular.otf`,
//   // src: `/media/Roboto/Roboto-Regular.ttf`
//   // src: `${process.env.PUBLIC_URL}/static/media/Roboto-Regular.ttf`
// });

// 定义样式
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     padding: 40,
//     fontFamily: "Noto Sans TC",
//   },
//   section: {
//     marginBottom: 10,
//   },
//   sectionWithBorder: {
//     marginBottom: 10,
//     padding: 1,
//     border: "1px solid black",
//   },
//   p2section: {
//     marginBottom: 10,
//   },
//   title: {
//     fontSize: 18,
//     marginBottom: 10,
//     textAlign: "center",
//   },
//   text: {
//     fontSize: 12,
//   },

//   rowtext: {
//     fontSize: 12,
//     marginBottom: 5,
//   },

//   // row: {
//   //   flexGrow: 1,
//   //   flexDirection: "row",
//   //   fontSize: 7,
//   // },
//   // col: {
//   //   flexGrow: 1,
//   //   flexDirection: "column",
//   // },
//   row: {
//     flexDirection: "row",
//     marginBottom: 5,
//   },
//   colLeft: {
//     width: "25%", // 左侧列宽度
//     paddingRight: 10,
//   },
//   colRight: {
//     width: "75%", // 右侧列宽度
//   },

//   p2row: {
//     flexDirection: "row",
//     marginBottom: 5,
//   },
//   p2colLeft: {
//     width: "5%", // 左侧列宽度
//     // paddingRight: 5,
//   },
//   p2colRight: {
//     width: "95%", // 右侧列宽度
//   },
//   p2rowtext: {
//     fontSize: 8,
//     marginBottom: 5,
//     maxWidth: "100%",
//   },

//   signatureSection: {
//     marginBottom: 10,
//     paddingTop: 40,
//   },
//   signatureRow: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//     marginTop: 70,
//   },
//   signatureCol: {
//     width: "40%",
//     borderTop: "1px solid black",
//     // textAlign: "center",
//     paddingTop: 5,
//   },
//   spacer: {
//     marginVertical: 5,
//   },
// });

// const LeaseAgreement = ({ leaseTable }) => {
//   console.log(leaseTable);
//   return (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <Header />

//         {/* <Page3 /> */}

//         <Page1 data={leaseTable} />

//         <Text break />
//         <Page2 />

//         <Text break />
//         <Page3 />
//       </Page>
//     </Document>
//   );
// };

const Header = () => (
  <View style={styles.section} fixed>
    <View style={styles.row}>
      <CompanyMarkLogo />
    </View>
  </View>
);

const Page1 = ({ leaseTable, leaseAddTable }) => {
  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, "0");
  };
  // console.log(leaseTable);
  // console.log(data.Id);

  // leaseTable.CreateDay = new Date().toLocaleDateString();
  // leaseTable.StartDate = new Date(leaseTable.StartDate).toLocaleDateString();

  const date = new Date(leaseTable.CreateDay);

  const startdate = new Date(leaseTable.StartDate);
  const enddate = new Date(leaseTable.EndDate);
  const formattedDate = date.toLocaleDateString("en-CA"); // 'en-CA' format is 'YYYY-MM-DD'

  const year = date.getFullYear();
  const month = padToTwoDigits(date.getMonth() + 1);
  const day = padToTwoDigits(date.getDate());

  const startYear = startdate.getFullYear();
  const startMonth = padToTwoDigits(startdate.getMonth() + 1);
  const startDay = padToTwoDigits(startdate.getDate());

  const endYear = enddate.getFullYear();
  const endMonth = padToTwoDigits(enddate.getMonth() + 1);
  const endDay = padToTwoDigits(enddate.getDate());

  const doorCardItem = leaseAddTable.find((item) => item.AdditionalId === "2");
  const doorCardQuantity = doorCardItem ? doorCardItem.Quantity : "0";

  const keyCardItem = leaseAddTable.find((item) => item.AdditionalId === "3");
  const keyCardQuantity = keyCardItem ? keyCardItem.Quantity : "0";

  return (
    <>
      <View style={styles.section}>
        <Text style={styles.text}>編號﹕RC- {leaseTable.Id} /24</Text>
        <Text style={styles.title}>會員租賃合約</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.rowtext}>
          本公司為協助有志創業而成立的會所，並為會員提供各種褔利及營商設施。
          包括多功能大堂、會議室、電子儲物柜、個人辦公檯及獨立辦公室。
        </Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.rowtext}>
          此合約由進晴國際顧問有限公司與會員於 {year} 年 {month} 月 {day}{" "}
          日協議訂立租約
        </Text>
      </View>
      {/* <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.rowtext}>會員名稱</Text>
          </View>
          <View style={styles.col}>
            <Text style={styles.rowtext}>：__________________ (中)</Text>
            <Text style={styles.rowtext}>：__________________ (英)</Text>
            <Text style={{ width: "100%" }}>會員名稱 : </Text>
          </View>
        </View>
      </View> */}
      <View style={styles.section}>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>會員名稱</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：(中) {leaseTable.C_CFN} {leaseTable.C_CLN}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>會員名稱</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：(英) {leaseTable.C_FN} {leaseTable.C_LN}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>商業登記/身份証號碼</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：{leaseTable.CNo} / {leaseTable.PId}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>聯絡電話/手提電話</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>：{leaseTable.Phone}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>電郵地址</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>：{leaseTable.Email}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>租期</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：由 {startYear} 年 {startMonth} 月 {startDay} 日 至 {endYear} 年{" "}
              {endMonth} 月 {endDay} 日 (包括首尾兩天)
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>房間號碼</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：九龍灣常悅道19號福康工業大厦 10 樓 3-9 室- {leaseTable.ItemName}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>租金</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：每月為港幣 HK$ {leaseTable.Price}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.colLeft}>
            <Text style={styles.rowtext}>保證金</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.rowtext}>
              ：港幣 HK$ {leaseTable.Deposit} (2 個月租金按金+$500 電費按金)
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.sectionWithBorder}>
        <Text style={styles.text}>
          本公司確認收到會員所交的款項港幣 HK${" "}
          {(
            parseFloat(leaseTable.Price) + parseFloat(leaseTable.Deposit)
          ).toFixed(2)}{" "}
          ( 2 個月租金的按金+$500 電費按金)
        </Text>
        <Text style={styles.text}>
          會員確認接收本公司所交予該單位的物品:大門門卡 {doorCardQuantity} 張
        </Text>
        <Text style={styles.rowtext}>
          辦公椅 1 張及櫃桶匙 {keyCardQuantity} 條
        </Text>

        <Text style={styles.text}>
          電錶: {leaseTable.EMeterPerMoney} 度@HK$3.50 度
        </Text>
        <Text style={styles.text}>
          炒錶日期: {year} 年 {month} 月 {day} 日
        </Text>
        <Text style={styles.text}>錶數: {leaseTable.EMeterNo}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.text}>
          本公司與承租會員同意並遵下列(見背面)條款。
        </Text>
      </View>

      {/* <View style={styles.section}>
        <Text style={styles.text}>進晴國際顧問有限公司</Text>
      </View> */}
      <View style={styles.signatureSection}>
        <View style={styles.signatureRow}>
          <View style={styles.signatureCol}>
            <Text style={styles.text}>進晴國際顧問有限公司</Text>
            <Text style={styles.text}>授權代表簽名</Text>
            <Text style={styles.text}>日期:</Text>
          </View>
          <View style={styles.signatureCol}>
            <Text style={styles.text}>承租會員簽名</Text>
            <Text style={styles.text}>香港身份證:</Text>
            <Text style={styles.text}>日期:</Text>
            {/* <View style={styles.row}>
              <Text style={{ width: "4%" }}>香港身份證:</Text>
              <Text style={styles.text}>日期:</Text>
              <Text style={{ width: "55%" }}>日期:</Text>
              <Text style={{ width: 10 }}>日期:</Text>
            </View> */}
          </View>
        </View>
      </View>

      {/* <View style={styles.section}>
        <View style={styles.row}>
          <Text style={{ width: "55%" }}>Remark : </Text>
          <Text style={{ width: "10%" }}></Text>
          <Text style={{ width: "10%" }}></Text>
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.text}>會員名稱：__________________ (中)</Text>
        <Text style={styles.text}>會員名稱：__________________ (英)</Text>
        <Text style={styles.text}>商業登記/身份証號碼：__________________</Text>
        <Text style={styles.text}>聯絡電話/手提電話：__________________</Text>
        <Text style={styles.text}>電郵地址：__________________</Text>
        <Text style={styles.text}>
          租期：由 202 年 月 日 至 202 年 月 日 (包括首尾兩天)
        </Text>
        <Text style={styles.text}>
          房間號碼：九龍灣常悅道19號福康工業大厦10樓3-9室
        </Text>
        <Text style={styles.text}>租金：每月為港幣HK$ ______</Text>
        <Text style={styles.text}>
          保證金：港幣HK$ ______ (2個月租金按金+$500 電費按金)
        </Text>
      </View>
      {/* 可以继续添加其他内容和部分 */}
    </>
  );
};

const Page2 = () => (
  <View style={styles.p2section}>
    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>1. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員必須於繳付訂金後至少一個月內簽妥租賃合約，如未能在指定時間內完成，本公司會視作放棄租用，有關訂金即予沒收。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>2. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員須在租期內每個月份第一天繳付指定的租金，不得藉詞拖欠。倘會員於應繳租金的七天後仍未清付該租金，
          則本公司有權採取適當行動追討會員所欠的租金，如會員仍未能將租金交到本公司或會員不履行合約內任何條款，
          則當作會員毀約自動退租，本公司有合法權利將此合約終止，收回該房間，而由此而引起的一切費用概由會員承擔。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>3. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員在沒有得到本公司書面同意前，不得對該單位作任何改動及/或加建。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>4. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員不得轉讓，除非會員獲得本公司書面同意，轉租或分租該單位或其任何部份或將該單位或其任何部份的佔用權讓予其他任何人等。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>5. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員如繼續租賃或退租，須至少一個月前以書面通知(續租則另訂新租約，方生效力)，否則會員須補償一個月租金給本公司。
          如本公司要收回有關單位，亦須於至少一個月前通知會員。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>6. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員不得在該單位內貯存違禁品及須遵守香港法律條例和規則。倘經發覺，即舉報有關當局究辦。
          會員不得有喧嘩或干擾其他會員安寧之舉動，倘經投訢，會員仍不作改善，本公司有權勒令會員遷出。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>7. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員須在租約期內保持單位內部的傢俬、牆壁、電燈、冷氣設備良好狀態(自然損耗及因固有的缺陷所產生的損壞除外)並須於約滿或終止時
          將單位在設備良好的狀態下交回本公司。如會員因疏忽而毀壞該單位之原有設備或損及別人或導致其他人等之任何傷害，會員須負責賠償。
          會員須自投風災、水、火、盜竊、意外及保險，會員如有任何損失，本公司不負任何責任。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>8. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員遷出時，必須在租期內將單位內傢俬雜物全部搬走以清手續，本公司所屬物品除外。
          如會員藉故不交回門匙或留下任何物品不予搬走，遷出後三天以內仍不取回作放棄權利論。
          本公司有權將該些物品清除，而有關額外費用(如搬遷費、清潔費)將在按金內扣除。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>9. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員須交與本公司保證金作為保證會員遵守及履行租賃合約的按金。
          若會員在租期內並無干犯此合約任何條款，則本公司將會於收回交吉的單位或一切會員欠款後(以較遲者作準)7天內無息退還該保證金與會員。
          但若會員違反此合約內任何條款，則此租約立即被終止:且本公司可沒收所有保證金。
          本公司沒收保證金的權利將不會影響本公司採取任何其他合法行動追討一切因會員違約所引致的損失的權利。
          會員應清楚明白保證金不能用作支付租金。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>10. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員未經本公司書面同意不得在本公司其他任何地方標貼或懸掛個人或團體之招牌，或具有宣傳性質之廣告。
          如會員不依約遵守，本公司得僱人拆除之，一切費用會員必須負責。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>11. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          若會員按時清繳租金和雜費及沒有干犯此合約內任何條款，則本公司不得在租約期內干擾會員享用該單位。
          唯會員不得拒絕本公司派遣之人員，在適當時間進入該單位檢視該單位近況或進行任何維修工程。
          當合約屆滿或終止前兩個月，在不干擾原則下，會員須准許本公司人員在合理之時間內進入該單位視察。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>12. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          本公司負責有關該單位的水費、管理費、物業稅及特定的電費。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>13. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>每度電按標準 HK$3.50 收取。</Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>14. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員可以在租期內借用會議室 _2_ 小時/月(不設累積及在辦公時間內)。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>15. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          會員可於租期內以優惠價錢享用本公司其他設施;詳情內容見附件一。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>16. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          請各會員愛惜使用所有公用設施，用完隨手整理清潔，令大眾能有舒適的共享空間。
        </Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>17. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>本公司與會員雙方同意遵守上述條款。</Text>
      </View>
    </View>

    <View style={styles.p2row}>
      <View style={styles.p2colLeft}>
        <Text style={styles.p2rowtext}>18. </Text>
      </View>
      <View style={styles.p2colRight}>
        <Text style={styles.p2rowtext}>
          每月租金請存入: 交通銀行(香港)有限公司
        </Text>
        <Text style={[styles.p2rowtext, { paddingLeft: "12%" }]}>
          帳戶號碼#382-556-1-047180-01
        </Text>

        <Text style={[styles.p2rowtext, { paddingLeft: "12%" }]}>
          帳戶名稱 "進晴國際顧問有限公司" 或 "Chung Ching International
          Consultant Company Limited"
        </Text>
      </View>
    </View>
  </View>
);

const Page3 = () => (
  <>
    <View style={styles.section}>
      <Text style={styles.text}>附件一: </Text>
    </View>
    <View style={styles.section}>
      <Text style={styles.text}>設施租賃收費表:</Text>
    </View>
    <View style={styles.section}>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View
            style={[styles.tableCol, { borderBottomWidth: 0, width: "25%" }]}
          >
            <Text style={styles.tableCell}>功能廳</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>星期一至五</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>星期六</Text>
          </View>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>星期日</Text>
          </View>
        </View>
        {/* // 以下是表格的一行 */}
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>(以時段收費)</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>全廳</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>半廳</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>全廳</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>半廳</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>全廳</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>半廳</Text>
          </View>
        </View>
        {/* // 以下是表格的一行 */}

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>上午 10:00 - 下午 13:00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,000.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$600.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,000.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$600.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$2,000.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,100.00</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>下午 14:30 - 下午 17:30</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,200.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$700.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,800.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,000.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$2,500.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,300.00</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>晚上 18:30 - 晚上 21:30</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,500.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$850.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$2,300.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,300.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$3,000.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$1,500.00</Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.section}>
      <Text style={[styles.text, { fontSize: 10.5 }]}>
        *如客人同一天租用連續兩節時段, 中段休息時間(即 13:00-14:30 /
        17:30-18:30)可豁免收費
      </Text>
    </View>
    <View style={styles.section}>
      <Text style={[styles.text, { fontSize: 10.5 }]}>
        *如客人租用一節時段而需要加時, 收費見下表:-
      </Text>
      <View style={[styles.table, { borderTopWidth: 0 }]}>
        <View style={styles.tableRow}>
          <View
            style={[styles.tableCol, { width: "25%" }, { borderTopWidth: 1 }]}
          >
            <Text style={styles.tableCell}>功能廳加時收費(小時)</Text>
          </View>
          <View style={[styles.tableCol, { borderTopWidth: 1 }]}>
            <Text style={styles.tableCell}>全廳</Text>
          </View>
          <View style={[styles.tableCol, { borderTopWidth: 1 }]}>
            <Text style={styles.tableCell}>半廳</Text>
          </View>

          {[...Array(4)].map((_, colIndex) => (
            <View
              style={[
                styles.tableCol,
                { borderRightWidth: 0, borderBottomWidth: 0 },
              ]}
              key={colIndex}
            >
              <Text style={styles.tableCell}></Text>
            </View>
          ))}
        </View>
        {/* // 以下是表格的一行 */}

        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>上午 13:00 - 下午 14:00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$350.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$200.00</Text>
          </View>
          {[...Array(4)].map((_, colIndex) => (
            <View
              style={[
                styles.tableCol,
                { borderRightWidth: 0, borderBottomWidth: 0 },
              ]}
              key={colIndex}
            >
              <Text style={styles.tableCell}></Text>
            </View>
          ))}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>下午 17:30 - 下午 18:30</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$400.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$250.00</Text>
          </View>
          {[...Array(4)].map((_, colIndex) => (
            <View
              style={[
                styles.tableCol,
                { borderRightWidth: 0, borderBottomWidth: 0 },
              ]}
              key={colIndex}
            >
              <Text style={styles.tableCell}></Text>
            </View>
          ))}
        </View>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, { width: "25%" }]}>
            <Text style={styles.tableCell}>晚上 21:30 以後</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$500.00</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>HK$300.00</Text>
          </View>
          {[...Array(4)].map((_, colIndex) => (
            <View
              style={[
                styles.tableCol,
                { borderRightWidth: 0, borderBottomWidth: 0 },
              ]}
              key={colIndex}
            >
              <Text style={styles.tableCell}></Text>
            </View>
          ))}
        </View>
      </View>
    </View>
    <View style={styles.section}>
      <Text style={[styles.text, styles.boldText]}>
        會議室: HK$250/小時(2 小時起)
      </Text>
      <Text style={styles.text}>
        *超 10 分鐘以半小時計，超 40 分鐘以一小時計
      </Text>
      <Text style={styles.text}>
        儲物櫃:C1 及C2區 -HK$500/月 *C3區 -HK$550/月
      </Text>
      <Text style={styles.text}>
        辦公桌: 定期 - HK$1,500/月 *臨時 - HK$120/日
      </Text>
      <Text style={styles.text}>
        茶室 - HK$250/節, 每節 4 小時 (需提前預約)
      </Text>
      <Text style={styles.text}>*免費提供著名茶葉及小食蛋糕</Text>
      <Text style={styles.text}>
        *茶室 5 位計, 額外加人每位 HK$50/節(以最早進場者時間計算)
      </Text>
    </View>

    <View style={[styles.section, { paddingTop: 20 }]}>
      <Text style={styles.text}>
        *承租會員可享有上述租賃費用 8
        折優惠，須提早通知預留位置，否則視符當時情況再作安排。
      </Text>
    </View>
    <View style={styles.section}>
      <Text style={styles.text}>
        以上收費只供參考，進晴國際顧問有限公司保留修改上述價目及時間表的權利而不作另行通知。
      </Text>
      <Text style={styles.text}>
        如有任何查詢，歡迎與會所職員聯絡 (查詢電話 28683232)。
      </Text>
    </View>
  </>
);

const LeasePDF = () => {
  // const outletContext = useOutletContext();
  // const leaseTable = outletContext?.leaseTable || {}; // 设置默认值为空对象，或者根据实际情况设置合适的默认值
  const { leaseTable, leaseAddTable } = useOutletContext();

  console.log("ggg", leaseTable);
  // console.log("fff", leaseTable.C_FN);

  console.log("fff", leaseAddTable);

  return (
    <div>
      {/* <h1>PDF 生成示例</h1>
      <PDFDownloadLink
        document={<LeaseAgreement />}
        fileName="lease_agreement.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "正在生成PDF..." : "下載PDF"
        }
      </PDFDownloadLink> */}

      <PDFViewer width="1000" height="600">
        <Document>
          <Page size="A4" style={styles.page}>
            <Header />

            {/* <Page3 /> */}

            <Page1 leaseTable={leaseTable} leaseAddTable={leaseAddTable} />

            <Text break />
            <Page2 />

            <Text break />
            <Page3 />
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

export default LeasePDF;
