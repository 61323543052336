import { useState, useEffect, useMemo } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";
import SelectBox from "../../components/items/SelectBox";
import Separator from "../../components/rows/Separator";

import Table from "../../components/rows/Table";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

export default function ClientAdd({ table }) {
  const text = lang.zh;
  let navigate = useNavigate();

  const { token } = useAuth();
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const response = await CallApi(token, "GET", table);
  //     if (response.success && response.success > 0) setData(response.tabledata);
  //   };
  //   getData();
  // }, [table]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    console.log(data);
    const response = await CallApi(token, "POST", table, data);
    console.log(response);
    if (response.success && response.success > 0) {
      // redirect(`/`);
      // return navigate("/accounting/");
      navigate("../");
    }
  };

  return (
    <>
      <h2>{text.add}</h2>
      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section>
          <Row>
            <Col className="col-6 mb-2">
              <InputBox id="cno" label={text.no} type="text" />
            </Col>

            <Col className="col-6 mb-2">
              <InputBox
                id="pid"
                label="商業登記/身份証號碼"
                type="text"
                // required="required"
              />
            </Col>

            <Separator />

            <Col className="col-6 mb-2">
              <InputBox
                id="firstname"
                label="名(Eng)"
                type="text"
                // required="required"
              />
            </Col>

            <Col className="col-6 mb-2">
              <InputBox
                id="lastname"
                label="姓(Eng)"
                type="text"
                // required="required"
              />
            </Col>

            <Col className="col-6 mb-2">
              <InputBox
                id="cfirstname"
                label="名(中)"
                type="text"
                // required="required"
              />
            </Col>

            <Col className="col-6 mb-2">
              <InputBox
                id="clastname"
                label="姓(中)"
                type="text"
                // required="required"
              />
            </Col>

            <Separator />

            <Col className="col-6 mb-2">
              <SelectBox
                id="gender"
                label="性別"
                data={[
                  { Id: "M", Name: "M" },
                  { Id: "F", Name: "F" },
                ]}
              />
            </Col>

            <Col className="col-6 mb-2">
              <InputBox
                id="phone"
                label={text.phone}
                type="number"
                // required="required"
              />
            </Col>

            <Col className="col-6 mb-2">
              <InputBox
                id="email"
                label="電郵地址"
                type="text"
                // required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="address"
                label={text.address}
                type="text"
                // required="required"
              />
            </Col>

            <Separator />

            <Col className="col-12 mb-2">
              <SelectBox
                id="type"
                label={text.member}
                data={[
                  { Id: "2", Name: "client" },
                  { Id: "3", Name: "member" },
                ]}
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox id="description" label="說明" type="text" />
            </Col>

            <Col className="col-12 mb-2">
              <Button className="col-12 btn btn-primary p-2" text={text.add} />
            </Col>
          </Row>
        </Section>
      </Form>
    </>
  );
}
