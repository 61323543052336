import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    row: {
        marginTop: 100,
        flexGrow: 1,
        flexDirection: 'row',
    },
    leftcol: {
        width: '50%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    rightcol: {
        width: '50%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    col: {
        // flexGrow: 1,
        flexDirection: 'column',
    },
});

const FooterQnITerms = ({ title }) => {
    return (
        <View style={styles.col}>
            <Text style={{ fontSize: 15 }}>Terms and Conditions</ Text>
            <Text>1 Payment Tems:50% deposit upon confirmalion of quotation</Text>
            {title == "Quotation" &&
                <Text>2 Validity:14 Days from Quotation Date</Text>
            }

            <Text style={{ fontSize: 15, marginTop: 20 }}>Payment Method</Text>
            <Text>1 Crossed cheque payable to "MEDICAL BROTHERS LIMITED"</Text>
            <Text>2 Deposit to the following bank account and send receipt to cs@medical-brother.com</Text>
            <Text>Bank Name   Bank of China(Hong Kong)Limited</Text>
            <Text>Account Name Medical Brothers Limited</Text>
            <Text>Account No.   012-740-1-024470-6</Text>
        </View>
    )
}
export default FooterQnITerms