export default function SelectBoxWithOutNo(data) {
    return (
        <>
            <label htmlFor={data.id} className="form-label">{data.label}{(data.required === "required" ? <span style={{ color: "red" }}> * </span> : '')} :</label>
            <select id={data.id} name={data.id} value={data.value} onChange={data.onChange} className="form-select" required={data.required}>
                <option value='' key=''>Please select</option>
                {data.data.map((rows) =>
                    <option
                        value={rows.Id}
                        key={rows.Id}
                        data-name={rows.Name}
                        data-price={rows.Price}
                        data-cost={rows.Cost}
                        data-type={rows.Type}
                    // data-product-type={rows.TypeId}
                    >{rows.Name}</option>

                )}
            </select>
        </>
    )
}