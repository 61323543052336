import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useTranslation } from 'react-i18next';


const AuthContext = createContext();

export const AuthProvider = ({ children, userData, id, type }) => {
    const tokenExpiryTime = 12 * 60 * 60 * 1000; // Token expiry time in 12 hours
    const [token, setToken] = useLocalStorage("token", userData);
    const [userId, setUserId] = useLocalStorage("userId", id);
    const [userType, setUserType] = useLocalStorage("userType", type);
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const setDefaultLanguage = (lng = "en") => {
        const storedLanguage = localStorage.getItem("language");
        if (!storedLanguage) {
            localStorage.setItem("language", lng);
            i18n.changeLanguage(lng);
        } else if (lng !== storedLanguage) {
            localStorage.setItem("language", lng);
            i18n.changeLanguage(lng);
        }
    };

    const UserId = async (data) => {
        setUserId(data, tokenExpiryTime);
    }

    const UserType = async (data) => {
        setUserType(data, tokenExpiryTime);
    }

    const login = async (data) => {
        const storedLanguage = localStorage.getItem("language");
        setToken(data, tokenExpiryTime); // Save token to local storage

        setDefaultLanguage(storedLanguage); // Set default language
        navigate("/main/dashboard", { replace: true });
    };

    const logout = () => {
        setToken(null);  // Remove token from local storage 
        navigate("/", { replace: true });
    };

    const value = useMemo(
        () => ({
            token,
            userId,
            userType,
            login,
            UserId,
            UserType,
            logout,
            setDefaultLanguage
        }),
        [userId, userType, token, setDefaultLanguage] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
