import { useState, useEffect, useMemo } from "react";
import {
  Form,
  redirect,
  useNavigate,
  useLoaderData,
  useParams,
} from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";
import SelectBox from "../../components/items/SelectBox";

import Table from "../../components/rows/Table";

import FormClientBase from "../../components/rows/FormClientBaseReNewForLeases";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

export default function LeaseRenewal({ table }) {
  let { itemId } = useParams();
  const text = lang.zh;
  const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();

  let navigate = useNavigate();

  const { token } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    data.id = itemId;
    console.log(data);
    const response = await CallApi(token, "PUT", table, data);
    console.log(response);
    if (response.success && response.success > 0) {
      navigate("/main/lease/");
    }
  };

  return (
    <>
      <h2>新增</h2>
      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section>
          <Row>
            <Col className="col-12 mb-2">
              <InputBox
                id="enddate"
                label="到期日期"
                type="datetime-local"
                defaultValue={theTime.substring(0, theTime.length - 5)}
                step={1}
                required="required"
              />
            </Col>
            <Col className="col-12 mb-2">
              <InputBox
                id="renewaldate"
                label="續約日期"
                type="datetime-local"
                defaultValue={theTime.substring(0, theTime.length - 5)}
                step={1}
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <Button className="col-12 btn btn-primary p-2" text="新增" />
            </Col>
          </Row>
        </Section>
      </Form>
    </>
  );
}
