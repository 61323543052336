import { useState, useEffect } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";

import Table from "../../components/rows/Table";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

export async function action({ request }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  const response = await CallApi("POST", "accounting-chart", data);
  if (response.success && response.success > 0) return redirect(`/accounting`);
}

// export async function loader() {
//   // const contact = await CallApi("GET", "accounting-chart");
//   // console.log(contact);
//   // if (!contact) {
//   //   throw new Response("", {
//   //     status: 404,
//   //     statusText: "Not Found",
//   //   });
//   // }
//   // return contact;
// }

export default function AccountingChart({ table }) {
  let navigate = useNavigate();

  const { token } = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", table);
      if (response.success && response.success > 0) setData(response.tabledata);
    };
    getData();
  }, [table]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    const response = await CallApi(token, "POST", "accounting-chart", data);
    console.log(response);
    if (response.success && response.success > 0) {
      // redirect(`/`);
      // return navigate("/accounting/");
      return navigate(0);
    }
  };

  // console.log(data);

  return (
    <>
      {/* <h2>Chart of Accounts</h2> */}
      <h5>新增會計科目</h5>

      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section>
          <Row>
            <Col>
              <InputBox id="name" label="新增編號" type="text" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Button className="col-2 btn btn-primary" text="新增" />
            </Col>
          </Row>
        </Section>
      </Form>

      <hr />

      <Section>
        {data.length > 0 ? <Table tabledata={data} /> : "nodata"}
      </Section>
    </>
  );
}
