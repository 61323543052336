import { useState, useEffect, useMemo } from "react";
import {
  NavLink,
  Outlet,
  Form,
  redirect,
  useNavigate,
  useLoaderData,
  useParams,
} from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";
import Table from "../../components/rows/Table";

export default function SalesDetail() {
  let { orderId } = useParams();
  const text = lang.zh;
  let navigate = useNavigate();
  const { token } = useAuth();

  const [salesTable, setSalesTable] = useState({});
  const [salesDetailTable, setSalesDetailTable] = useState([]);

  const salesTableName = "sales";
  const salesDetailTableName = "sales-detail";
  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", salesTableName, {
        Id: orderId,
      });
      const response2 = await CallApi(token, "GET", salesDetailTableName, {
        SallingId: orderId,
      });
      // console.log("m", response);
      // console.log("d", response2);
      if (response.success && response.success > 0)
        setSalesTable(response.tabledata[0]);
      if (response2.success && response2.success > 0)
        setSalesDetailTable(response2.tabledata);
    };
    getData();
  }, [orderId]);

  console.log("m", salesTable);
  // console.log("d", salesDetailTable);

  const opendrawbox = async (event) => {
    event.preventDefault();
    // console.log(state)
    // opendraw();
    // const data = await opendraw();
    // console.log(data)
    CallApi(null, "GET", "http://192.168.1.190/index.php");
  };

  return (
    <>
      <Section>
        <Row>
          <Col>{text.client} : </Col>
          <Col>
            {salesTable.Lname} 先生/小姐 {salesTable.Phone}
          </Col>
        </Row>
        <Row>
          <Col>{text.clientid} : </Col>
          <Col>{salesTable.CNo}</Col>
        </Row>
        <Row>
          <Col>{text.invoiceid} : </Col>
          <Col>{salesTable.SNo}</Col>
        </Row>
        <Row>
          <Col>{text.date} : </Col>
          <Col>{salesTable.CustomerDay}</Col>
        </Row>
        <Row>
          <Col>{text.staff} : </Col>
          <Col>{salesTable.UpdateUser}</Col>
        </Row>
      </Section>
      <hr />
      <Section>
        <Row>
          <Col>{text.price} : </Col>
          <Col>${salesTable.Price}</Col>
        </Row>
        <Row>
          <Col>{text.discount} : </Col>
          <Col>{salesTable.Discount}%</Col>
        </Row>
        <Row>
          <Col>{text.totalprice} : </Col>
          <Col>${salesTable.Price * salesTable.Discount}</Col>
        </Row>
      </Section>
      <hr />
      <Section>
        <table className="table">
          <thead>
            <tr>
              <th>{text.id}</th>
              <th>{text.name}</th>
              <th>{text.price}</th>
              <th>{text.qty}</th>
              <th>{text.discount}</th>
              <th>{text.totalprice}</th>
            </tr>
          </thead>
          <tbody>
            {salesDetailTable.map((rows, index) => (
              <tr key={index}>
                <td>{rows.Id}</td>
                <td>{rows.ProductName}</td>
                <td>{rows.Price}</td>
                <td>{rows.Quantity}</td>
                <td>{rows.Discount}</td>
                <td>{rows.Price * rows.Quantity * rows.Discount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Section>

      <Section></Section>

      {/* <Section>
        <button type="submit" onClick={opendrawbox}>
          open draw
        </button>
      </Section> */}

      {/* PDF */}
      <Section>
        <Row>
          <Col className="col">
            <NavLink to="quotation">{text.quotation}</NavLink>{" "}
            <NavLink to="invoice">{text.invoice}</NavLink>{" "}
            <NavLink to="receipt">{text.receipt}</NavLink>
          </Col>
          <hr />
          <Outlet context={{ salesTable, salesDetailTable }} />
        </Row>
      </Section>
    </>
  );
}
