import { useOutletContext } from "react-router-dom";
import {
  Document,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import CompanyMarkLogo from "../../components/pdf/pdfbase/CompanyMarkLogo";
import FooterQnITerms from "../../components/pdf/pdfbase/FooterQnITerms";
import FooterReceiptTerms from "../../components/pdf/pdfbase/FooterReceiptTerms";
import FooterRowR from "../../components/pdf/pdfbase/FooterRowR";
import FooterRowQnI from "../../components/pdf/pdfbase/FooterRowQnI";
import HeaderRow from "../../components/pdf/pdfbase/HeaderRow";

// Create styles
Font.register({
  family: "Noto_Sans",
  // src: `/pdfbase/Noto_Sans_TC/NotoSansTC-Regular.otf`,
  src: `/media/Noto_Sans_TC/NotoSansTC-Regular.otf`,
  // src: `/media/Roboto/Roboto-Regular.ttf`
  // src: `${process.env.PUBLIC_URL}/static/media/Roboto-Regular.ttf`
});

const styles = StyleSheet.create({
  page: {
    // fontFamily: 'Helvetica',
    // fontFamily: "Noto_Sans",
    fontSize: 11,
    paddingTop: 10,
    paddingLeft: 60,
    paddingRight: 60,
    // lineHeight: 1.5,
    // flexDirection: 'column'
    // flexDirection: 'row',
    // backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1
  },
  row: {
    flexGrow: 1,
    flexDirection: "row",
  },
  col: {
    flexGrow: 1,
    flexDirection: "column",
  },
  header: {
    fontSize: 6,
  },
  FooterTerms: {
    fontSize: 8,
  },
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>
);

export default function PDFSheet({ title }) {
  const { salesTable, salesDetailTable } = useOutletContext();
  const tableRowsCount = 11;
  const blankRows = Array(tableRowsCount - salesDetailTable.length).fill(0);
  console.log(salesTable);

  return (
    <PDFViewer width="1000" height="600">
      <Document>
        <Page size="A4" style={styles.page}>
          <Header title={title} />

          <View style={styles.section}>
            <HeaderRow data={salesTable} />
          </View>

          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={{ width: "10%" }}>Item</Text>
              <Text style={{ width: "45%" }}>Product Description</Text>
              <Text style={{ width: "10%" }}>Qty</Text>
              <Text style={{ width: "15%" }}>Unit Price(HK$)</Text>
              <Text style={{ width: "10%" }}>Dis(%)</Text>
              <Text style={{ width: "10%" }}>Amount(HK$)</Text>
            </View>
            {salesDetailTable.map((item, index) => (
              <View style={styles.row} key={index}>
                <Text style={{ width: "10%" }}>{index + 1}</Text>
                <Text style={{ width: "45%" }}>{item.ProductName}</Text>
                <Text style={{ width: "10%" }}>{item.Quantity}</Text>
                <Text style={{ width: "15%" }}>{item.Price}</Text>
                <Text style={{ width: "10%" }}>{item.Discount}</Text>
                <Text style={{ width: "10%" }}>
                  $ {item.Price * item.Quantity * item.Discount}
                </Text>
              </View>
            ))}

            {blankRows.map((x, i) => (
              <View style={styles.row} key={`BR${i}`}>
                <Text style={{ width: "10%" }}> </Text>
                <Text style={{ width: "45%" }}> </Text>
                <Text style={{ width: "10%" }}></Text>
                <Text style={{ width: "15%" }}>-</Text>
                <Text style={{ width: "10%" }}></Text>
                <Text style={{ width: "10%" }}>-</Text>
              </View>
            ))}
          </View>

          <View style={styles.section}>
            <View style={styles.row}>
              <Text style={{ width: "55%" }}>Remark : </Text>
              <Text style={{ width: "10%" }}></Text>
              <Text style={{ width: "10%" }}></Text>
              <Text style={{ width: "15%" }}>Total : </Text>
              <Text style={{ width: "10%" }}>$ {salesTable.Price}</Text>
            </View>
          </View>

          <Footer title={title} data={salesTable} />
        </Page>
      </Document>
    </PDFViewer>
  );
}

const Header = ({ title }) => (
  <View style={styles.section}>
    <View style={styles.row}>
      <CompanyMarkLogo />
      <Text style={{ fontSize: 20 }}>{title}</Text>
    </View>
  </View>
);

const Footer = ({ title, data }) => (
  <View style={styles.section}>
    <View style={styles.FooterTerms}>
      {title != "Receipt" && <FooterQnITerms title={title} />}
      {title == "Receipt" && <FooterReceiptTerms data={data} />}
    </View>

    {title != "Receipt" && <FooterRowQnI />}
    {title == "Receipt" && <FooterRowR />}
  </View>
);
