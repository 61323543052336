import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    row: {
        flexGrow: 1,
        flexDirection: 'row',
    },
    leftcol: {
        width: '60%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    rightcol: {
        width: '20%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    col: {
        // flexGrow: 1,
        flexDirection: 'column',
    },
});


const HeaderRow = ({ data }) => {
    return (
        <View>
            <Text>To: </Text>

            <View style={styles.row}>
                <View style={styles.leftcol}>
                    <Text> </Text>
                    <Text> </Text>
                    <Text>Attn : Mr./Ms.{data.Lname} </Text>
                    <Text>Phone: {data.Phone}</Text>
                </View>
                <View style={styles.rightcol}>
                    <Text>Invoice No. : </Text>
                    <Text>Date : </Text>
                    <Text>Customer No. : </Text>
                    <Text>Staff :</Text>
                </View>
                <View style={styles.col}>
                    <Text>{data.SNo}</Text>
                    <Text>{data.CustomerDay}</Text>
                    <Text>{data.CNo}</Text>
                    <Text>{data.UpdateUser}</Text>

                </View>
            </View>
        </View>
    )
}

export default HeaderRow;