import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { AppBar } from "./AppBar";
import Main from "../rows/Main";
// import { Page_urls } from "../../utilities/Page_urls_section";
import PageUrls from "../../utilities/PageUrls.json";

import "../../sass/Style.scss";

export const MainLayout = () => {
  // console.log(Page_urls()[1]);
  const { token } = useAuth();
  const outlet = useOutlet();

  // if user is logged in, redirect to profile page
  // if (token) {
  //   return <Navigate to="/main/profile" replace />;
  // }

  //if user is not logged in, redirect to login page
  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex flex-nowrap sidebars">
      <AppBar sections={PageUrls} />
      <Main className="container-fluid overflow-scroll" style={{backgroundColor : "edf1f5"}}>{outlet}</Main>
    </div>
  );
};

// { label: "主頁", path: "/" },

// // Main Business
// { label: "收銀", path: "/order" },
// { label: "銷售", path: "/sales" },
// { label: "租賃", path: "/lease" },
// // { label: "維修", path: "/repair" },

// // Member
// { label: "客人", path: "/client" },

// // Store
// { label: "倉存", path: "/stock" },

// // Supplier List
// { label: "產品", path: "/product" },
// { label: "供應商", path: "/supplier" },
// { label: "生產商", path: "/manufacturer" },

// // Accounting
// { label: "會計", path: "/accounting" },
