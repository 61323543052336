import { useState, useEffect, useMemo } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
import InputBox from "../components/items/InputBox";
import Button from "../components/items/Button";
import SelectBox from "../components/items/SelectBox";

import Table from "../components/rows/Table";
import RowReactTableFilter from "../components/reactTable/RowReactTableFilter";

import { CallApi } from "../hooks/services";
import { useAuth } from "../hooks/useAuth";

export default function PageMainWithReactTable({ title, table, col }) {
  let navigate = useNavigate();

  const { token } = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", table);
      // if (response.success && response.success > 0) setData(response.tabledata);
      if (response.success && response.success > 0) {
        const updatedData = response.tabledata.map((item) => ({
          ...item,
          Price: item.Price !== undefined ? item.Price : undefined,
        }));
        setData(updatedData);
      }
    };
    getData();
  }, [table]);

  const tabledata = useMemo(() => data, [data]);

  const columns = useMemo(() => col, [col]);

  return (
    <>
      <h2>{title}</h2>
      <Section>
        <Row>
          <Col>
            {/* {data.length > 0 ? <Table tabledata={data} /> : "nodata"} */}
            {data.length > 0 ? (
              <RowReactTableFilter columns={columns} data={tabledata} />
            ) : (
              "nodata"
            )}
          </Col>
        </Row>
      </Section>
    </>
  );
}
