import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';

import logo from '../assets/Chung_Ching_logon1-1.png';


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        padding: 30,
        fontFamily: "Noto Sans TC",
    },
    section: {
        marginBottom: 10,
    },
    title: {
        fontSize: 18,
        marginBottom: 10,
        textAlign: "center",
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
});


const CompanyMarkLogo = () => {
    return (
        <View style={styles.section}>

            <Image style={styles.logo} src={logo} />

            {/* <View style={styles.section}>
                <Text style={styles.title}>Medical Brothers Limited</Text>
                <Text>FLAT/RM E, 5/F, WAI CHEUNG INDUSTRIAL CENTRE,</Text>
                <Text>5 SHEK PAI TAU ROAD, TUEN MUN, NT HONG KONG</Text>
                <Text>Tel: (852) 2153-3521 / 9468-2067 Fax: (852) 2153-3522</Text>
                <Text>Email: cs@medical-brother.com</Text>
                <Text>Website: https://medical-brother.com/</Text>
            </View> */}
        </View>

    )
}

export default CompanyMarkLogo