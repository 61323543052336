import { useState, useEffect, useMemo } from "react";
import {
  NavLink,
  Outlet,
  Form,
  redirect,
  useNavigate,
  useLoaderData,
  useParams,
} from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";
import Table from "../../components/rows/Table";

export default function SalesDetail() {
  let { itemId } = useParams();
  const text = lang.zh;
  let navigate = useNavigate();
  const { token } = useAuth();

  const [leaseTable, setLeaseTable] = useState({});
  const [leaseAddTable, setLeaseAddTable] = useState([]);
  const [leasePaymentTable, setLeasePaymentTable] = useState([]);

  const leaseTableName = "lease";
  const leaseAddTableTableName = "lease-detail-additional";
  const leasePaymentTableTableName = "lease-payment";
  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", leaseTableName, {
        Id: itemId,
      });
      const response2 = await CallApi(token, "GET", leaseAddTableTableName, {
        LeaseId: itemId,
      });
      const response3 = await CallApi(
        token,
        "GET",
        leasePaymentTableTableName,
        {
          LeaseId: itemId,
        }
      );
      // console.log("m", response);
      // console.log("d", response2);
      if (response.success && response.success > 0)
        setLeaseTable(response.tabledata[0]);
      if (response2.success && response2.success > 0)
        setLeaseAddTable(response2.tabledata);
      if (response3.success && response3.success > 0)
        setLeasePaymentTable(response3.tabledata);
    };
    getData();
  }, [itemId]);

  console.log("m", leaseTable);
  // console.log("d", salesDetailTable);

  const opendrawbox = async (event) => {
    event.preventDefault();
    // console.log(state)
    // opendraw();
    // const data = await opendraw();
    // console.log(data)
    CallApi(null, "GET", "http://192.168.1.190/index.php");
  };

  // `Id`,
  // `ItemId`,
  // `ItemName`,
  // `CId`,
  // `C_FN`,
  // `C_LN`,
  // `StartDate`,
  // `EndDate`,
  // `Deposit`,
  // `RenewalDate`,
  // `CreateDay`,
  // `UpdateDay`,
  // `UpdateUser`

  return (
    <>
      <Section>
        <Row>
          <Col>房間號碼 : </Col>
          <Col>{leaseTable.ItemName}</Col>
        </Row>
        <Row>
          <Col>{text.client} : </Col>
          <Col>{leaseTable.C_FN + " " + leaseTable.C_LN} 先生/小姐 </Col>
        </Row>
        <Row>
          <Col>{text.client} (中) : </Col>
          <Col>{leaseTable.C_CFN + " " + leaseTable.C_CLN} 先生/小姐 </Col>
        </Row>
        <Row>
          <Col>電話 : </Col>
          <Col>{leaseTable.Phone}</Col>
        </Row>
        <Row>
          <Col>電郵 : </Col>
          <Col>{leaseTable.Email}</Col>
        </Row>
        <Row>
          <Col>ID : </Col>
          <Col>{leaseTable.CNo}</Col>
        </Row>
        <Row>
          <Col>PID : </Col>
          <Col>{leaseTable.PId}</Col>
        </Row>

        <Row>
          <Col>開始日期 : </Col>
          <Col>{leaseTable.StartDate}</Col>
        </Row>

        <Row>
          <Col>到期日期 : </Col>
          <Col>{leaseTable.EndDate}</Col>
        </Row>

        <Row>
          <Col>按金 : </Col>
          <Col>{leaseTable.Deposit}</Col>
        </Row>
      </Section>
      <hr />
      <Section>
        <Row>
          <Col>續租 : </Col>
          <Col>{leaseTable.RenewalDate}</Col>
        </Row>

        <Row>
          <Col>最後更改 : </Col>
          <Col>{leaseTable.UpdateUser}</Col>
        </Row>

        <Row>
          <Col>最後更改日期 : </Col>
          <Col>{leaseTable.UpdateDay}</Col>
        </Row>
        <Row>
          <Col>創建日期 : </Col>
          <Col>{leaseTable.CreateDay}</Col>
        </Row>
      </Section>
      <hr />

      <Section>
        <Row>
          <Col>電錶 : (度@HK$3.50 度)</Col>
          <Col>{leaseTable.EMeterPerMoney}</Col>
        </Row>
        <Row>
          <Col>錶數 : </Col>
          <Col>{leaseTable.EMeterNo}</Col>
        </Row>
        <Row>
          <Col>每月價格 : </Col>
          <Col>${leaseTable.Price}</Col>
        </Row>
      </Section>

      <hr />

      <Section>
        <Col>附加物品 : </Col>
        <Col>
          {leaseAddTable.map((rows, index) => (
            <tr key={index}>
              <td>{rows.AdditionalName}</td>
              <td>{rows.Quantity}</td>
            </tr>
          ))}
        </Col>
      </Section>

      <Section>
        <Col>交租紀錄 : </Col>
        <Col>
          {leasePaymentTable.map((rows, index) => (
            <tr key={index}>
              <td>{rows.Type}</td>
              <td>{rows.Amount}</td>
              <td>{rows.CreateDay}</td>
            </tr>
          ))}
        </Col>
      </Section>

      {/* <Section>
        <button type="submit" onClick={opendrawbox}>
          open draw
        </button>
      </Section> */}

      {/* PDF */}
      <Section>
        <Row>
          <Col>租約 : </Col>
        </Row>
        <Row>
          <Col className="col">
            <NavLink to="agreement">lease agreement</NavLink>{" "}
            <NavLink to="LeasePDF">LeasePDF</NavLink>{" "}
            {/* <NavLink to="invoice">{text.invoice}</NavLink>{" "}
            <NavLink to="receipt">{text.receipt}</NavLink> */}
          </Col>
          <hr />
          <Outlet context={{ leaseTable, leaseAddTable }} />
        </Row>
      </Section>
    </>
  );
}
