import { useOutlet } from "react-router-dom";

import Section from "../rows/Section";
import { SubBar } from "../base/SubBar";

import {
  GetAppBarSection,
  GetAppBarSectionUrlsByID,
} from "../../utilities/Util";

export default function LeaseLayout() {
  const appBarSection = "lease_collapse";
  const appBarLabel = "lease";
  const appBarPath = GetAppBarSectionUrlsByID(appBarSection, appBarLabel);

  const outlet = useOutlet();
  return (
    <>
      <h1>租用</h1>
      <Section>
        <SubBar
          pages={[
            { label: "總結", path: appBarPath },
            // { label: "列表", path: "/supplier/list" },
            { label: "新增", path: `${appBarPath}/add` },
            // { label: "續租", path: `${appBarPath}/renewal` },
            { label: "房間", path: `${appBarPath}/item` },
            { label: "房間增加", path: `${appBarPath}/itemadd` },

            { label: "附加物品", path: `${appBarPath}/additional` },
            { label: "增加附加物品", path: `${appBarPath}/additionaladd` },
          ]}
        />
      </Section>
      <Section>{outlet}</Section>
    </>
  );
}
