import { Suspense } from "react";
import {
  useAsyncError,
  useLoaderData,
  useOutlet,
  Await,
} from "react-router-dom";
// import LinearProgress from "@mui/material/LinearProgress";
// import Alert from "@mui/material/Alert";
import { AuthProvider } from "../../hooks/useAuth";

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { userPromise } = useLoaderData();

  function ReviewsError() {
    const error = useAsyncError();
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <Await
        resolve={userPromise}
        // errorElement={<Alert severity="error">Something went wrong!</Alert>}
        // errorElement={console.log("errorElement: Something went wrong!")}
        // errorElement={<ErrorDisplay />}
        errorElement={<ReviewsError />}
        children={(token) => (
          <AuthProvider userData={token}>{outlet}</AuthProvider>
        )}
      />
    </div>
  );
};
