import { Link } from "react-router-dom";
export function SalesSummary() {
  return [
    { Header: "Id", accessor: "Id", disableFilters: true },
    { Header: "SNo", accessor: "SNo" },
    { Header: "ClientId", accessor: "ClientId" },
    { Header: "CNo", accessor: "CNo" },
    { Header: "Name", accessor: "Name" },
    { Header: "Lname", accessor: "Lname" },
    { Header: "Phone", accessor: "Phone" },
    { Header: "Address", accessor: "Address" },
    { Header: "Price", accessor: "Price" },
    { Header: "Discount", accessor: "Discount" },
    { Header: "Description", accessor: "Description" },
    { Header: "State", accessor: "State" },
    { Header: "CreateUser", accessor: "CreateUser" },
    { Header: "CustomerDay", accessor: "CustomerDay" },
    {
      Header: "Details",
      Cell: (rows) => <Link to={"detail/" + rows.row.original.Id}>Detial</Link>,
    },
  ];
}

export function LeasingSummary() {
  return [
    { Header: "Id", accessor: "Id", disableFilters: true },
    { Header: "LNo", accessor: "LNo" },
    { Header: "ClientId", accessor: "ClientId" },
    { Header: "CNo", accessor: "CNo" },
    { Header: "Name", accessor: "Name" },
    { Header: "Lname", accessor: "Lname" },
    { Header: "Phone", accessor: "Phone" },
    { Header: "Address", accessor: "Address" },

    { Header: "CName", accessor: "CName" },
    { Header: "EName", accessor: "EName" },
    { Header: "IdCard", accessor: "IdCard" },
    { Header: "Email", accessor: "Email" },
    { Header: "Soc", accessor: "EconomicStatus" },
    { Header: "SocNo", accessor: "EconomicStatusText" },

    { Header: "Type", accessor: "LeasingType" },
    { Header: "StartDate", accessor: "StartDate" },
    { Header: "EndDate", accessor: "EndDate" },
    { Header: "Description", accessor: "Description" },
    { Header: "State", accessor: "State" },
    { Header: "CreateUser", accessor: "CreateUser" },
    { Header: "CustomerDay", accessor: "CustomerDay" },
    {
      Header: "Details",
      Cell: (rows) => <Link to={"detail/" + rows.row.original.Id}>Detial</Link>,
    },
  ];
}

export function LeaseSummary() {
  // `Id`,
  // `ItemId`,
  // `ItemName`,
  // `CId`,
  // `C_FN`,
  // `C_LN`,
  // `StartDate`,
  // `EndDate`,
  // `Deposit`,
  // `RenewalDate`,
  // `CreateDay`,
  // `UpdateDay`,
  // `UpdateUser`
  return [
    { Header: "Id", accessor: "Id", disableFilters: true },
    { Header: "ItemId", accessor: "ItemId" },
    { Header: "ItemName", accessor: "ItemName" },
    // { Header: "CId", accessor: "CId" },
    // { Header: "C_FN", accessor: "C_FN" },
    { Header: "C_LN", accessor: "C_LN" },
    // { Header: "StartDate", accessor: "StartDate" },
    { Header: "EndDate", accessor: "EndDate" },
    // { Header: "Deposit", accessor: "Deposit" },
    { Header: "RenewalDate", accessor: "RenewalDate" },
    // { Header: "CreateDay", accessor: "CreateDay" },
    // { Header: "UpdateDay", accessor: "UpdateDay" },
    // { Header: "UpdateUser", accessor: "UpdateUser" },
    {
      Header: "Details",
      Cell: (rows) => <Link to={"detail/" + rows.row.original.Id}>Detial</Link>,
    },
    {
      Header: "Renewal",
      Cell: (rows) => <Link to={"renewal/" + rows.row.original.Id}>Renew</Link>,
    },
    {
      Header: "額外物品",
      Cell: (rows) => (
        <Link to={"detailadditional/" + rows.row.original.Id}>add</Link>
      ),
    },
    {
      Header: "交租",
      Cell: (rows) => (
        <Link to={"rentpayment/" + rows.row.original.Id}>支付</Link>
      ),
    },
  ];
}

export function StaffSummary() {
  return [
    { Header: "Id", accessor: "Id", disableFilters: true },
    { Header: "Ac", accessor: "Ac" },
    { Header: "First Name", accessor: "First_name" },
    { Header: "Last Name", accessor: "Last_name" },
    { Header: "Type", accessor: "Type" },
    { Header: "Create Day", accessor: "Create_day" },
    { Header: "Update Day", accessor: "Update_day" },
    { Header: "Update User", accessor: "Update_user" },
    {
      Header: "Details",
      Cell: (rows) => <Link to={"detail/" + rows.row.original.Id}>Detial</Link>,
    },
  ];
}
