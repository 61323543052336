import * as React from "react";

// import { useNavigate, NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
// import { useTranslation } from "react-i18next";
// import _ from "lodash";
// import { Select, Option } from "../rows/Select";

import "../../sass/SideBars.scss";

// const { Option } = Select;

export const AppBar = ({ sections }) => {
  // const { t } = useTranslation();

  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const navigate = useNavigate();
  const { token, userType, logout, setDefaultLanguage } = useAuth();

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };

  // const handleCloseNavMenu = (path) => {
  //   setAnchorElNav(null);
  //   if (path) {
  //     navigate(path);
  //   }
  // };

  const Logout = ({ id }) => {
    if (id === "account_collapse" && token)
      return (
        <li>
          <a
            onClick={logout}
            href="/"
            className="link-body-emphasis d-inline-flex text-decoration-none rounded"
          >
            Logout
          </a>
        </li>
      );
  };

  // console.log("AppBar", userType);
  return (
    // <div>
    //   {/* React Router Auth */}
    //   {pages?.map((page) => (
    //     <a key={page.label} href={page.path}>
    //       {page.label}
    //     </a>
    //     // <span textAlign="center">{page.label}</span>
    //   ))}
    //   {!!token && <a onClick={logout}> {"logout"}</a>}
    //   {/* <br />
    //   React Router Auth
    //   {pages?.map((page) => (
    //     <a key={page.label} href={page.path}>
    //       {page.label}
    //     </a>
    //   ))}
    //   {!!token && <a onClick={logout}> {"logout"}</a>} */}
    // </div>

    <div className="d-flex flex-nowrap">
      {/* <div className="flex-shrink-0 p-3" style={{ width: "200px" }}> */}
      <div
        className="d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary"
        style={{ width: "200px" }}
      >
        {/* <a href="/" className="d-flex align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom"> */}
        <a
          href="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
        >
          {/* <svg className="bi pe-none me-2" width="30" height="24">
            <use href="#bootstrap"></use>
          </svg> */}
          <span className="fs-5 fw-semibold">MBsys</span>
        </a>
        <hr />
        {/* <ul className="list-unstyled ps-0"> */}
        <ul className="nav nav-pills flex-column mb-auto">
          {/* Show sections Start*/}
          {sections.map((section, index) => {
            const isExpanded = section.expanded ? " show" : "";

            if (section.isDivider) {
              return <li key={index} className="border-top my-3"></li>;
            } else {
              return (
                <li key={section.title} className="mb-1">
                  <button
                    className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${section.id}`}
                    aria-expanded={section.expanded ? "true" : "false"}
                  >
                    {section.title}
                  </button>
                  <div className={`collapse${isExpanded}`} id={section.id}>
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      {section.pages.map((page) => {
                        if (page.SuperUser && userType !== "2") {
                          return null; // Hide page if SuperUser is true and userType is not superuser
                        }
                        return (
                          <li key={page.label}>
                            <a
                              href={page.path}
                              className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                            >
                              {page.label}
                            </a>
                          </li>
                        );
                      })}
                      <Logout id={section.id} />
                    </ul>
                  </div>
                </li>
              );
            }
          })}
          {/* Show sections End*/}
        </ul>
        <hr />
        <div className="dropdown">
          <a
            href="/404"
            className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://github.com/mdo.png"
              alt=""
              width="32"
              height="32"
              className="rounded-circle me-2"
            />
            <strong>mdo</strong>
          </a>
          <ul className="dropdown-menu text-small shadow">
            <li>
              <a className="dropdown-item" href="/404">
                New project...
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/404">
                Settings
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/404">
                Profile
              </a>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => setDefaultLanguage("en")}
              >
                English
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => setDefaultLanguage("zh_hant")}
              >
                中文（繁體）
              </button>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="/404">
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="b-example-divider b-example-vr"></div>
    </div>
  );
};
