import Row from "../rows/Row";

export default function InputButton(data) {
    return (
        <input className={data.className}
            id={data.id}
            name={data.id}
            type="button"
            value={data.value}
            onChange={data.onChange}
            onClick={data.onClick}
            placeholder={data.placeholder}
            aria-label={data.placeholder}
            min={(data.type === "number" ? '0' : '')}
            list={data.list}
            defaultValue={data.defaultValue}
            required={data.required}
        />
    )
}