import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';

import logo from '../assets/logo.png';


// Create styles
const styles = StyleSheet.create({
    row: {
        flexGrow: 1,
        flexDirection: 'row',
        fontSize: 7,
    },
    col: {
        flexGrow: 1,
        flexDirection: 'column',
    },
    title: {
        fontSize: 13,
        // margin: 1,
    },
    logo: {
        width: 50,
        height: 50,
        // marginLeft: 'auto',
        marginRight: 5
    }
});


const CompanyMarkLogo = () => {
    return (

        <View style={styles.row}>
            <Image style={styles.logo} src={logo} />

            <View style={styles.col}>
                <Text style={styles.title}>Medical Brothers Limited</Text>
                <Text>FLAT/RM E, 5/F, WAI CHEUNG INDUSTRIAL CENTRE,</Text>
                <Text>5 SHEK PAI TAU ROAD, TUEN MUN, NT HONG KONG</Text>
                <Text>Tel: (852) 2153-3521 / 9468-2067 Fax: (852) 2153-3522</Text>
                <Text>Email: cs@medical-brother.com</Text>
                <Text>Website: https://medical-brother.com/</Text>
            </View>
        </View>

    )
}

export default CompanyMarkLogo