export default function Table({ tabledata }) {
  //   console.log(tabledata);
  return (
    <table className="table">
      <thead>
        <tr>
          {Object.keys(tabledata[0]).map((data, index) => (
            <th key={index} scope="col">
              {data}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tabledata.map((rows, index) => (
          <tr key={index}>
            {Object.keys(rows).map((row) =>
              row === "Id" ? (
                <th key={row}>{rows[row]}</th>
              ) : (
                <td key={row}>{rows[row]}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
      <tfoot></tfoot>
    </table>
  );
}
