import { useState, useEffect, useMemo } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";
import SelectBox from "../../components/items/SelectBox";

import Table from "../../components/rows/Table";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

export default function ProductAdd({ table }) {
  const text = lang.zh;
  let navigate = useNavigate();

  const { token } = useAuth();

  const [data, setData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);

  const typeTable = "product-type";
  const supplierTable = "supplier";
  const manufacturerTable = "manufacturer";
  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", typeTable);
      const response2 = await CallApi(token, "GET", supplierTable);
      const response3 = await CallApi(token, "GET", manufacturerTable);
      if (response.success && response.success > 0) setData(response.tabledata);
      if (response2.success && response2.success > 0)
        setSupplierData(response2.tabledata);
      if (response3.success && response3.success > 0)
        setManufacturerData(response3.tabledata);
    };
    getData();
  }, [typeTable, supplierTable, manufacturerTable]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    console.log(data);
    const response = await CallApi(token, "POST", table, data);
    console.log(response);
    if (response.success && response.success > 0) {
      // return navigate(`/${table}`);
      navigate("../");
    }
  };

  return (
    <>
      <h2>{text.add}</h2>
      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section>
          <Row>
            <Col className="col-12 mb-2">
              <InputBox
                id="name"
                label={text.product_name}
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="price"
                label={text.price}
                type="text"
                required="required"
              />
            </Col>
            <Col className="col-12 mb-2">
              <InputBox
                id="cost"
                label={text.cost}
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <SelectBox id="type" label={text.type} data={data} />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="model"
                label={text.model}
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="code"
                label={text.code}
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <SelectBox
                id="supplier"
                label={text.supplier}
                data={supplierData}
              />
            </Col>

            <Col className="col-12 mb-2">
              <SelectBox
                id="manufacturer"
                label={text.manufacturer}
                data={manufacturerData}
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox id="description" label="說明" type="text" />
            </Col>

            <Col className="col-12 mb-2">
              <Button className="col-12 btn btn-primary p-2" text={text.add} />
            </Col>
          </Row>
        </Section>
      </Form>
    </>
  );
}
