import { useState, useEffect, useMemo } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";

import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import FormClientBase from "../../components/rows/FormClientBase";

// import RowGetViewTableToSelect from "components/RowGetViewTableToSelect";
import InputBox from "../../components/items/InputBox";
import InputButton from "../../components/items/InputButton";
import SelectBox from "../../components/items/SelectBox";
import SelectBoxWithOutNo from "../../components/items/SelectBoxWithOutNo";
import Button from "../../components/items/Button";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

// import DBToJs from "../../components/base/JsDec";
// import JsDec from "../../components/base/JsDec";

export default function ShopOrderUI({ table }) {
  // console.log(JsDec.DBToJs(100));
  const text = lang.zh;
  let navigate = useNavigate();
  const { token } = useAuth();

  // const [data, setData] = useState([]);
  const [state, setState] = useState({
    productQuantity: 1,
    cart: [],
    pmethod: 2,
    discount: 100,
    totalprice: 0.0,
    dollar: 0.0,
  });
  // console.log(state);

  const [cat, setCat] = useState();
  const [catTable, setCatTable] = useState([]);
  const [productTable, setProductTable] = useState([]);

  const catTableName = "product-type";
  const productTableName = "product";
  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", catTableName);
      const response2 = await CallApi(token, "GET", productTableName);
      if (response.success && response.success > 0)
        setCatTable(response.tabledata);
      if (response2.success && response2.success > 0)
        setProductTable(response2.tabledata);
    };
    getData();
  }, [catTableName]);

  const addbuttonToCart = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    const product = {
      // [e.target.name]: e.target.value,
      productId: e.target.getAttribute("data-id"),
      productName: e.target.getAttribute("data-name"),
      productPrice: e.target.getAttribute("data-price"),
      productQuantity: state.productQuantity,
    };
    // console.log(product);
    const newcart = state.cart;
    newcart.push(product);
    setState({
      ...state,
      cart: newcart,
      productQuantity: 1,
      totalprice:
        state.totalprice + product.productPrice * product.productQuantity,
    });
  };

  const deleteCartItem = (index) => {
    const cart = state.cart;
    const product = state.cart[index];
    // console.log(state.orderInfo.cart[index]);
    cart.splice(index, 1);

    setState({
      ...state,
      cart,
      totalprice:
        state.totalprice - product.productPrice * product.productQuantity,
    });

    console.log("deleted");
    // console.log(state.orderInfo);
  };

  const onChangeValue = (e) => {
    setState({ ...state, [e.target.name]: parseInt(e.target.value) });
  };

  const handleSubmit = async (event) => {
    console.log("submit");
    // console.log(event.target.value);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    // console.log(data);

    data.cart = state.cart;
    data.totalprice = state.totalprice;
    data.state = 2;

    delete data.productQuantity;
    delete data.dollar;

    const response = await CallApi(token, "POST", table, data);
    // console.log("result", response.message);
    if (response.success && response.success > 0) {
      return navigate(`/main/sales/detail/${response.message}`);
    }
  };

  return (
    <>
      <h1>ShopOrderUI</h1>

      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section
          style={{
            backgroundColor: "#DDD",
            padding: "10px",
            borderRadius: "25px",
          }}
        >
          <FormClientBase />
        </Section>

        <hr />

        <Section
          style={{
            border: "1px solid #F00",
            padding: "10px",
            borderRadius: "25px",
          }}
        >
          <Row>
            <Col>
              <Row>
                <InputBox
                  label="條碼掃描／打貨品編號搜尋"
                  type="text"
                  list="browsers"
                />
                <datalist id="browsers">
                  <option value="AAA" />
                  <option value="ACCCCC" />
                </datalist>
              </Row>
              <div style={{ minHeight: "600px", overflowX: "auto" }}>
                <Row>
                  <div className="col-1">ID</div>
                  <div className="col-4">Item</div>
                  <div className="col-2">$/件</div>
                  <div className="col-2">Price</div>
                  {/* <div className="col-2"></div> */}
                  <div className="col-1">Del</div>
                </Row>

                <hr />

                {state.cart.map((item, index) => (
                  <Row className="row" key={index}>
                    <Col className="col-1">{index + 1}</Col>
                    <Col className="col-4">
                      {item.productId + ". " + item.productName}
                    </Col>
                    <Col className="col-2">
                      {"$" + item.productPrice + " / " + item.productQuantity}
                    </Col>
                    <Col className="col-2">
                      {"$" + item.productPrice * item.productQuantity}
                    </Col>
                    {/* <Col className="col-2"> */}
                    {/* <a color="secondary" onClick={() => addOneCartItem(index)}>+</a> {" "} */}
                    {/* </Col> */}
                    <Col className="col-1">
                      <InputButton
                        className="btn btn-primary"
                        value="X"
                        onClick={() => deleteCartItem(index)}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
              總額: {state.totalprice} <br />
              總額(折扣): {(state.totalprice * state.discount) / 100} <br />
            </Col>

            <Col>
              <Row>
                <label>Category:</label>
                {catTable.map((item) => (
                  <InputButton
                    className="col-3 btn btn-primary m-1"
                    key={item.Id}
                    name="catSelect"
                    value={item.Name}
                    onClick={() => setCat(item.Id)}
                  />
                ))}
              </Row>
              <hr />
              <Row>
                <label>Item:</label>
                {productTable.map(
                  (item) =>
                    cat === item.TypeId && (
                      <input
                        type="button"
                        className="col-3 btn btn-primary m-1"
                        style={{ minHeight: "80px", whiteSpace: "normal" }}
                        key={item.Id}
                        name="productId"
                        data-id={item.Id}
                        data-name={item.Name}
                        data-price={item.Price}
                        // data-cost={item.Cost}
                        onClick={addbuttonToCart}
                        value={item.Id + ". " + item.Name}
                      />
                    )
                )}
              </Row>

              <hr />

              <Row>
                <Col className="col-6">
                  <InputBox
                    id="productQuantity"
                    label="數量"
                    type="number"
                    value={state.productQuantity}
                    // defaultValue={1}
                    onChange={onChangeValue}
                  />
                </Col>
              </Row>

              <hr />
              <Row>
                <Col>
                  付款方法:
                  <SelectBox
                    id="pmethod"
                    label={text.pmethod}
                    onChange={onChangeValue}
                    value={state.pmethod}
                    data={[
                      { Id: 2, Name: "cash" },
                      { Id: 3, Name: "octopus" },
                      { Id: 4, Name: "credit" },
                      { Id: 5, Name: "alipay" },
                      { Id: 6, Name: "wechat" },
                      { Id: 7, Name: "unionpay" },
                    ]}
                  />
                </Col>
                <Col>
                  <SelectBoxWithOutNo
                    id="discount"
                    label={text.discount}
                    onChange={onChangeValue}
                    value={state.discount}
                    data={[
                      { Id: 100, Name: "100%" },
                      { Id: 95, Name: "95%" },
                      { Id: 90, Name: "90%" },
                      { Id: 85, Name: "85%" },
                      { Id: 80, Name: "80%" },
                      { Id: 75, Name: "75%" },
                      { Id: 70, Name: "70%" },
                      { Id: 65, Name: "65%" },
                      { Id: 60, Name: "60%" },
                      { Id: 55, Name: "55%" },
                      { Id: 50, Name: "50%" },
                      { Id: 45, Name: "45%" },
                      { Id: 40, Name: "40%" },
                      { Id: 35, Name: "35%" },
                      { Id: 30, Name: "30%" },
                      { Id: 25, Name: "25%" },
                      { Id: 20, Name: "20%" },
                      { Id: 15, Name: "15%" },
                      { Id: 10, Name: "10%" },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-3">
                  <InputBox
                    id="dollar"
                    label="收款"
                    type="number"
                    onChange={(e) =>
                      setState({ ...state, [e.target.name]: e.target.value })
                    }
                    value={state.dollar}
                    // step={0.1}
                    step="0.01"
                  />
                  找續: {state.dollar - state.totalprice}
                </Col>
              </Row>
              <Row>
                <InputButton
                  className="col-3 btn btn-primary p-2 m-1"
                  value="1000"
                  onClick={() => setState({ ...state, dollar: 1000 })}
                />{" "}
                <InputButton
                  className="col-3 btn btn-primary p-2 m-1"
                  value="500"
                  onClick={() => setState({ ...state, dollar: 500 })}
                />{" "}
                <InputButton
                  className="col-3 btn btn-primary p-2 m-1"
                  value="300"
                  onClick={() => setState({ ...state, dollar: 300 })}
                />{" "}
                <InputButton
                  className="col-3 btn btn-primary p-2 m-1"
                  value="100"
                  onClick={() => setState({ ...state, dollar: 100 })}
                />
              </Row>
              {/* <Row>
                <InputButton
                  className="col-4 btn btn-primary p-2 m-1"
                  value="開錢箱"
                />
              </Row> */}
              <Row>
                {/* <InputButton
                  className="col-4 btn btn-primary p-2 m-1"
                  value="下訂單"
                /> */}
                <Button
                  id="checkout"
                  className="col-4 btn btn-primary p-2 m-1"
                  value={2}
                  text="結帳"
                />
              </Row>
            </Col>
          </Row>
        </Section>

        <hr />
      </Form>
    </>
  );
}
