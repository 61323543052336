import axios from "axios";
import { useAuth } from "./useAuth";
import { matchSorter } from "match-sorter";

const domainUrl =
  window.location.host == "localhost:3000"
    ? "http://" + window.location.hostname + "/api/v1/"
    : "http://ccisys.anktech.io/api/v1/";
// : "http://192.168.1.104/api/v1/";
// "http://192.168.1.198/api/v1/";

// console.log(window.location.host, window.location.hostname);
// console.log(window.location.host == "localhost:3000" ? "YY" : "NN");

// Define the base URL
const Axios = axios.create({
  //   baseURL: "http://localhost/api/v1/",
  baseURL: domainUrl,
  // baseURL: "http://192.168.1.198/api/v1/",
});

export function CallApifun(tokenkey, type, url, query = []) {
  const { token } = useAuth();
  const aa = CallApi(tokenkey, type, url, query);
}

export async function CallApi(tokenkey, type, url, query = []) {
  // console.log(login);
  try {
    let method = type.toString().toLowerCase();
    let endpoint = url.toString();
    let data = query;

    // console.log("method:", method, "endpoint:", endpoint, "data:", data);
    Axios.defaults.headers.common["Authorization"] = "bearer " + tokenkey;

    let response = await Axios[method](
      endpoint,
      method === "get" ? { params: data } : data
    );

    // console.log("Serlovices Call Api:", response);

    return response.data;

    // let { data } = await Axios.get("articles");
    // // console.log(data);
    // if (!data) data = [];
    // if (query) {
    //     data = matchSorter(data, query, { keys: ["title"] });
    // }
    // return data ?? null;
  } catch (error) {
    console.log(error);
  }
  return null;
}

export default CallApi;

export async function LoginUser(type, url, query) {
  try {
    let method = type.toString().toLowerCase();
    let endpoint = url.toString();
    let data = query;

    // console.log(
    //   "method: " + method,
    //   "endpoint: " + domainUrl + endpoint,
    //   "data: " + data
    // );

    // method = domainUrl + method;

    let response = await Axios[method](
      endpoint,
      method === "get" ? { params: data } : data
    );

    // const login = await Axios.post(endpoint, data);
    // console.log(response);
    // return login;

    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
}

export async function IsLoggedIn(token) {
  //   console.log("token");
  //   const { token } = useAuth();
  //   const loginToken = localStorage.getItem("loginToken");

  // If inside the local-storage has the JWT token
  if (token) {
    //Adding JWT token to axios default header
    Axios.defaults.headers.common["Authorization"] = "bearer " + token;

    // Fetching the user information
    const { data } = await Axios.get("user-info");

    // let response = await Axios["get"]("user-info");

    console.log(data);

    return data.data;
  }
}
