import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const DefaultLanguage = localStorage.getItem("language") || "en";
// const DefaultLanguage = localStorage.getItem("language") || "zh_hant";

i18n
  .use(Backend) // use i18next-http-backend to load translations from your backend
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome to React and react-i18next",
    //     },
    //   },
    // },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    lng: DefaultLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: DefaultLanguage,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
