import { NavLink, useOutlet } from "react-router-dom";
import Main from "../rows/Main";
import Section from "../rows/Section";
import { SubBar } from "../base/SubBar";

import {
  GetAppBarSection,
  GetAppBarSectionUrlsByID,
} from "../../utilities/Util";

export default function SalesLayout() {
  const appBarSection = "sales_collapse";
  const appBarLabel = "sales";
  const appBarPath = GetAppBarSectionUrlsByID(appBarSection, appBarLabel);

  const outlet = useOutlet();

  return (
    <>
      <h1>銷售</h1>

      <Section>
        <SubBar
          pages={[
            { label: "Summary", path: appBarPath },
            { label: "List", path: `${appBarPath}/list` },
            { label: "Day", path: `${appBarPath}/day` },
            { label: "Week", path: `${appBarPath}/week` },
            { label: "Month", path: `${appBarPath}/month` },
          ]}
        />
      </Section>
      <Section>{outlet}</Section>
    </>
  );
}
