import Section from "../components/rows/Section";
import Row from "../components/rows/Row";
import Col from "../components/rows/Col";
export default function Page404() {
  return (
    <>
      <h2>404 Error</h2>
      <Section>
        <Row>
          <Col>404 Page Not Found</Col>
        </Row>
      </Section>
    </>
  );
}
