import { useState, useEffect, useMemo } from "react";
import {
  Form,
  redirect,
  useNavigate,
  useLoaderData,
  Link,
} from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";
import SelectBox from "../../components/items/SelectBox";

import Table from "../../components/rows/Table";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

export default function Accounting({ table }) {
  let navigate = useNavigate();

  const { token } = useAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", table);
      if (response.success && response.success > 0) setData(response.tabledata);
    };
    getData();
  }, [table]);

  return (
    <>
      <h2>會計</h2>
      <Section>
        <Row>
          <Col>{data.length > 0 ? <Table tabledata={data} /> : "nodata"}</Col>
        </Row>
      </Section>
    </>
  );
}

export function Tabletext() {
  const text = () => import("../../utilities/langs/lang.json");
  console.log(text);
  return [
    { Header: text.id, accessor: "Id" },
    { Header: text.title, accessor: "Title" },
    // { Header: text.cost, accessor: 'Cost', },
    { Header: text.description, accessor: "Description" },
    { Header: text.price, Cell: (rows) => rows.row.original.Price },

    { Header: text.quantity, accessor: "Quantity" },
    { Header: text.type, accessor: "Type" },
    { Header: text.chart_name, accessor: "ChartName" },
    { Header: text.customer_day, accessor: "Customer_Day" },
    { Header: text.user, accessor: "User" },
    // {
    //   Header: text.details,
    //   Cell: (rows) => (
    //     <Link to={linktext + "/" + rows.row.original.Id}>{text.edit}</Link>
    //   ),
    // },
  ];
}
