import { useState, useEffect, useMemo } from "react";
import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import Section from "../../components/rows/Section";
import Row from "../../components/rows/Row";
import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Button from "../../components/items/Button";
import SelectBox from "../../components/items/SelectBox";

import Table from "../../components/rows/Table";

import FormClientBase from "../../components/rows/FormClientBaseV2ForLeases";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

export default function LeaseAdd({ table }) {
  const text = lang.zh;
  const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();

  let navigate = useNavigate();

  const { token } = useAuth();
  const [data, setData] = useState([]);

  const charttable = "lease-item";

  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", charttable);
      if (response.success && response.success > 0) setData(response.tabledata);
    };
    getData();
  }, [charttable]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData);

    console.log(data);
    const response = await CallApi(token, "POST", table, data);
    console.log(response);
    if (response.success && response.success > 0) {
      // redirect(`/`);
      // return navigate("/accounting/");
      // return navigate(`/${table}`);
      navigate("../");
    }
  };

  return (
    <>
      <h2>新增</h2>
      <Form method="post" id="contact-form" onSubmit={handleSubmit}>
        <Section>
          <Row>
            <Col className="col-12 mb-2">
              <SelectBox id="item" label="房間" data={data} />
            </Col>

            <Col className="col-12 mb-2">
              <FormClientBase />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="deposit"
                label="按金"
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="electricity_meter_per_money"
                label="度數@HKD$"
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <InputBox
                id="electricity_meter_no"
                label="錶數"
                type="text"
                required="required"
              />
            </Col>

            <Col className="col-12 mb-2">
              <Button className="col-12 btn btn-primary p-2" text="新增" />
            </Col>
          </Row>
        </Section>
      </Form>
    </>
  );
}
