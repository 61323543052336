import * as React from "react";
import { Navigate, Form } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { LoginUser } from "../../hooks/services";

import Row from "../../components/rows/Row";
// import Col from "../../components/rows/Col";
import InputBox from "../../components/items/InputBox";
import Main from "../../components/rows/Main";

export const LoginPage = () => {
  const { token } = useAuth();
  const { UserType } = useAuth();
  const { UserId } = useAuth();
  const { login } = useAuth();

  if (token) {
    return <Navigate to="/main/dashboard" replace />;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = Object.fromEntries(data);

    const userdata = await LoginUser("POST", "login", {
      ac: user.username,
      password: user.password,
    });

    console.log(userdata);

    if (userdata.success && userdata.success > 0) {
      login(userdata["token"]);
      UserType(userdata["user_type"]);
      UserId(userdata["user_id"]);
    }

    // login(
    //   {
    //     email: data.get("email"),
    //     password: data.get("password"),
    //   }
    // );
  };

  return (
    <Main className="main container">
      <Row>
        Login
        <Form onSubmit={handleSubmit}>
          <InputBox
            label="User Name"
            id="username"
            type="text"
            aria-label="User Name"
            placeholder="User Name"
            required
          />
          <InputBox
            label="Password"
            id="password"
            type="password"
            aria-label="Password"
            placeholder="Password"
            required
          />
          <button type="submit" className="btn btn-primary m-3">
            Log in
          </button>
        </Form>
      </Row>
    </Main>
  );
};
