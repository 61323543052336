import PageUrls from "./PageUrls.json";

export const GetAppBarSection = (findTitle) => {
  // return appBarJson.find((section) => section.title === "Account");
  return PageUrls.find((section) => section.title === findTitle);
};

export const GetAppBarSectionUrlsByTitle = (findTitle, findLabel) => {
  return PageUrls.find((section) => section.title === findTitle).pages.find(
    (page) => page.label === findLabel
  ).path;
};

export const GetAppBarSectionUrlsByID = (findID, findLabel) => {
  return PageUrls.find((section) => section.id === findID).pages.find(
    (page) => page.label === findLabel
  ).path;
};
