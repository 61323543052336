import { Text, Font, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    row: {
        flexGrow: 1,
        flexDirection: 'row',
    },
    leftcol: {
        width: '50%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    rightcol: {
        width: '50%',
        flexGrow: 1,
        flexDirection: 'column',
    },
    col: {
        // flexGrow: 1,
        flexDirection: 'column',
    },
});

// Create styles
Font.register({
    family: "Noto_Sans",
    // src: `/pdfbase/Noto_Sans_TC/NotoSansTC-Regular.otf`,
    src: `/media/Noto_Sans_TC/NotoSansTC-Regular.otf`,
    // src: `/media/Roboto/Roboto-Regular.ttf`
    // src: `${process.env.PUBLIC_URL}/static/media/Roboto-Regular.ttf`
});

const FooterReceiptTerms = ({ data }) => {
    return (
        <View>
            <View style={styles.row}>
                <Text style={{ width: '55%' }}></Text>
                <Text style={{ width: '10%' }}></Text>
                <Text style={{ width: '25%' }}>Peyment Method : </Text>
                <Text style={{ width: '10%' }}>$ {data.Price}</Text>
            </View>
            <View style={styles.row}>
                <Text style={{ width: '55%' }}></Text>
                <Text style={{ width: '10%' }}></Text>
                <Text style={{ width: '25%' }}>Amount Received : </Text>
                <Text style={{ width: '10%' }}>$ {data.Price}</Text>
            </View>
            <View style={styles.row}>
                {/* <Text style={{ width: '10%' }}></Text> */}
                {/* <Text style={{ width: '55%' }}>{data.Address}</Text> */}
                <Text style={{ width: '55%' }}></Text>
                {/* <Text style={{ width: '55%', fontFamily: "Noto_Sans", }}>{data.Address}</Text> */}
                <Text style={{ width: '10%' }}></Text>
                <Text style={{ width: '15%' }}></Text>
                <Text style={{ width: '10%' }}></Text>
            </View>
            <View style={{ marginBottom: 20 }} />

            <View style={styles.row}>

                <View style={styles.col}>
                    <Text style={{ fontSize: 15 }}>Terms & conditions : </ Text>
                    <Text>Malfunctioned products can be exchanged upon the presentation of the receipt and the pacing within 7 days after</Text>
                    <Text>patronage . we regret that for reasons of hygiene, personal use products cannot be exchanged.</Text>
                    <Text>Customers must keep the original purchase receipt as confirmation of the warranty effective date</Text>
                    <Text>For any opinion or comment , please call the customer service department at 2153 3521</Text>
                    <Text>In case of any dispute,Medical Brothers Limited. reserves the rights for any final decision.</Text>
                </View>
            </View>
        </View>

    )
}
export default FooterReceiptTerms