import Row from "../rows/Row";

export default function InputBox(data) {
    return (
        <>
            <label htmlFor={data.id} className="form-label">{data.label}{(data.required === "required" ? <span style={{ color: "red" }}> * </span> : '')}:</label>
            <input className="form-control"
                id={data.id}
                name={data.id}
                type={data.type}
                value={data.value}
                onChange={data.onChange}
                placeholder={data.placeholder}
                aria-label={data.placeholder}
                min={(data.type === "number" ? '0' : '')}
                list={data.list}
                defaultValue={data.defaultValue}
                required={data.required}
            />
        </>
    )
}