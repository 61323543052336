import { useState, useEffect, useMemo } from "react";
import Row from "./Row";
import Col from "./Col";

import InputBox from "../items/InputBox";
import SelectBox from "../items/SelectBox";
// import RowGetViewTableToDataList from "../items/RowGetViewTableToDataList";
import RowDataList from "./RowDataList";

import { CallApi } from "../../hooks/services";
import { useAuth } from "../../hooks/useAuth";

import lang from "../../utilities/langs/lang.json";

export default function FormClientBase({ children, className, style }) {
  const text = lang.zh;
  const theTime = new Date(+new Date() + 8 * 3600 * 1000).toISOString();

  const [data, setData] = useState([]);
  const { token } = useAuth();

  const clientTable = "client";

  useEffect(() => {
    const getData = async () => {
      const response = await CallApi(token, "GET", clientTable);
      if (response.success && response.success > 0) setData(response.tabledata);
    };
    getData();
  }, [clientTable]);

  // console.log(theTime.substring(0, theTime.length - 5));

  return (
    <>
      <Row>
        <Col>
          <InputBox
            id="no"
            label={text.invoice_id}
            type="text"
            required="required"
          />
        </Col>
        <Col>
          <InputBox
            id="date"
            label={text.date}
            type="datetime-local"
            defaultValue={theTime.substring(0, theTime.length - 5)}
            step={1}
            required="required"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectBox
            id="client"
            label={text.client}
            data={data}
            defaultValue={1}
            // required="required"
          />
          {/* <RowDataList
            id="client"
            label={text.client}
            // head={data.headdata}
            data={data}
            // onChange={urldata.onChange}
          /> */}
        </Col>
        <Col>
          <InputBox id="description" label={text.description} type="text" />
        </Col>
      </Row>
    </>
  );
}
