import { useOutlet } from "react-router-dom";
import Section from "../rows/Section";
import { SubBar } from "../base/SubBar";

// import { SupplierAdd } from "../../pages/supplier/SupplierAdd";
import {
  GetAppBarSection,
  GetAppBarSectionUrlsByID,
} from "../../utilities/Util";

export default function SupplierLayout() {
  const appBarSection = "supplier-list_collapse";
  const appBarLabel = "supplier";
  const appBarPath = GetAppBarSectionUrlsByID(appBarSection, appBarLabel);

  const outlet = useOutlet();

  return (
    <>
      <h1>Supplier</h1>
      <Section>
        <SubBar
          pages={[
            { label: "總結", path: appBarPath },
            // { label: "列表", path: "/supplier/list" },
            { label: "新增", path: `${appBarPath}/add` },
          ]}
        />
      </Section>
      <Section>{outlet}</Section>
    </>
  );
}
