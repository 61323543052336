import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue, expiryTime) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(keyName);
            if (item) {
                const parsedItem = JSON.parse(item);
                const now = new Date();
                // 檢查是否過期
                if (now.getTime() > parsedItem.expiry) {
                    window.localStorage.removeItem(keyName);
                    return defaultValue;
                }
                return parsedItem.value;
            } else {
                const expiry = new Date().getTime() + expiryTime;
                const valueToStore = { value: defaultValue, expiry };
                window.localStorage.setItem(keyName, JSON.stringify(valueToStore));
                return defaultValue;
            }
        } catch (err) {
            console.log("Error reading from localStorage");
            return defaultValue;
        }
    });

    const setValue = (newValue, expiryTime = null) => {
        try {
            if (newValue === null) {
                window.localStorage.removeItem(keyName);
                setStoredValue(null);
            } else {
                const expiry = new Date().getTime() + expiryTime;
                const valueToStore = { value: newValue, expiry };
                window.localStorage.setItem(keyName, JSON.stringify(valueToStore));
                setStoredValue(newValue);
            }
        } catch (err) {
            console.log("Error writing to localStorage");
        }
    };

    return [storedValue, setValue];
};
